import React, { useContext, useEffect, useState, useRef } from "react";
import styles from './gallery.module.scss'
import useWindowSize from "../Helpers/hooks/useWindowSize";
/*import anime from 'animejs/lib/anime.es.js';*/
import { ThemeContext } from "../Context/context";

import translation from "../Helpers/translation";
import usePrevious from "../Helpers/hooks/usePrevious";

function Gallery(props) {

    const [galleryOpen, setGalleryOpen] = useState(false);
    const [touchstartX, setTouchstartX] = useState(0);
    const [transition, setTransition] = useState(0);
    const [showPhotos, setShowPhotos] = useState(9);
    const [photoPosition, setPhotoPosition] = useState();
    const showContext = useContext(ThemeContext);
    const animationRef = useRef()

    const openHandler = (event) => {
        setPhotoPosition(event.target.dataset.image)
        setGalleryOpen(!galleryOpen)
        document.getElementsByTagName('body')[0].style.height = window.innerHeight + "px"
        document.getElementsByTagName('body')[0].style.overflow = "hidden"
        setTimeout(()=> {
            document.getElementsByClassName(styles.track)[0].style.transition = "0.6s ease-in-out transform"
        },100)

    }

    const hideHandler = () => {
        setGalleryOpen(!galleryOpen)
        document.getElementsByTagName('body')[0].style.height = "100%"
        document.getElementsByTagName('body')[0].style.overflow = "auto"
        document.getElementsByClassName(styles.track)[0].style.transition = "unset"
    }

    const size = useWindowSize()
    const prevSize = usePrevious(size)

    const slideHandler = (touchendX) => {
        if (touchendX < touchstartX && Math.abs(touchendX - touchstartX) > 10) {
            if( size.width > 600) {
                if( transition !== (-size.width +120) * (props.photos.length - 1) ) {
                    setTransition(transition - size.width - 120)
                    setPhotoPosition(parseInt(photoPosition) + 1)
                }
            } else {
                if( transition !== -size.width * (props.photos.length - 1) ) {
                    setTransition(transition - size.width)
                    setPhotoPosition(parseInt(photoPosition) + 1)
                }
            }

        }

        if (touchendX > touchstartX && Math.abs(touchendX - touchstartX) > 10) {
            if( size.width < 600) {
                if( transition !== 0 ) {
                    setTransition(transition + size.width - 120)
                    setPhotoPosition(parseInt(photoPosition) - 1)
                }
            } else {
                if( transition !== 0 ) {
                    setTransition(transition + size.width)
                    setPhotoPosition(parseInt(photoPosition) - 1)
                }
            }

        }
    }

    const slideLeft = () => {
        if( transition !== 0 ) {
            setTransition(transition + size.width - 120)
            setPhotoPosition(parseInt(photoPosition) - 1)
        }
    }

    const slideRight = () => {
        if( transition !== (-size.width +120) * (props.photos.length - 1) ) {
            setTransition(transition - size.width - 120)
            setPhotoPosition(parseInt(photoPosition) + 1)
        }
    }

    const touchstartHandler = (event) => {
        if(event.changedTouches) {
            setTouchstartX(event.changedTouches[0].screenX)
        } else {
            setTouchstartX(event.clientX)
        }
    }

    const touchendHandler = (event) => {
        let touchendX = 0
        if(event.changedTouches) {
            touchendX = event.changedTouches[0].screenX
        } else {
            touchendX = event.clientX
        }
        slideHandler(touchendX)
    }

    useEffect(() => {
        if(size.width <= 750 && size.width > 450) {
            if(showPhotos % 2 !== 0) {
                setShowPhotos(showPhotos + 1)
            }
        } else if( size.width > 750) {
            if(showPhotos % 3 !== 0) {
                setShowPhotos(showPhotos - showPhotos % 3)
            }
        }
    },[size.width, showPhotos])

    useEffect(() => {
        const height = document.getElementsByClassName(styles.data)[0].clientHeight
        const elements = document.getElementsByClassName(styles.item)

        Array.from(elements).forEach((item) => {
            item.style.maxHeight = `calc(100vh - ${height}px - 100px)`
        })

        if( size.width > 600) {
            setTransition(-photoPosition * (size.width - 120))
        } else {
            setTransition(-photoPosition * size.width)
        }

        if(prevSize) {
            if(size.width !== prevSize.width) {
                document.getElementsByClassName(styles.track)[0].style.transition = "unset"

                setTimeout(()=> {
                    document.getElementsByClassName(styles.track)[0].style.transition = "0.6s ease-in-out transform"
                },100)
            }
        }
    },[size.width, photoPosition, size.height, prevSize])

    return (
        <div className={styles.wrapper}>
            {props.photos.map((item, index) => {
                return (
                    showPhotos > index
                        ? <div key={index} className={`${styles.imageWrapper} ${styles.fadeIn}`} onClick={(event) => openHandler(event)}>
                            <div className={styles.image} style={{backgroundImage: `url("${item.filename}")`}} data-image={index}/>
                        </div>
                        : null
                )
            })}
            <div className={styles.morePhotosWrapper}>
                <div className={styles.morePhotos} id={"moreButton"}
                     onClick={() => {setShowPhotos(showPhotos + 9)}}
                     style={props.photos.length <= showPhotos ? {display: "none"} : null}
                >
                    <span className={styles.more}>{translation.multimedia.morePhotos}</span>
                    <div ref={animationRef} className={`${styles.moreArrow} ${showContext.theme === 'light' ? styles.color : styles.black}`}/>
                </div>
            </div>
            <div className={`${styles.galleryWrapper} ${galleryOpen ? styles.galleryWrapperOpen : ''}`}>
                <div className={styles.gallery}>
                    <div className={styles.trackWrapper}>
                        <div className={styles.track} style={{transform : `translateX(${transition}px)`}}>
                            {props.photos.map((item, index) => {
                                return (
                                    <div key={index} className={styles.itemWrapper}>
                                        <img src={item.filename} className={styles.item} data-image={index} alt={''}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={styles.data}>
                        <span dangerouslySetInnerHTML={{__html: props.title}}/>
                    </div>
                </div>
            </div>
            <div className={`${styles.galleryWrapperToucher} ${galleryOpen ? styles.galleryWrapperToucherOpen : ''}`}
                 onTouchStart={touchstartHandler}
                 onTouchEnd={touchendHandler}
                 onMouseDown={touchstartHandler}
                 onMouseUp={touchendHandler}
            >
                <div className={`${styles.close} ${showContext.theme === "light" ?  styles.closeWhite : styles.closeBlack}` } onClick={hideHandler}/>
                <div className={styles.arrowLeft} onClick={slideLeft}>
                    <svg version="1.1" viewBox="0 0 23.335 42.426" xmlns="http://www.w3.org/2000/svg">
                        <path d="m21.213 7.0711e-7 -21.213 21.213 21.213 21.213 2.1213-2.1213-19.092-19.092 19.092-19.092z" fill="#fff" strokeWidth="0"/>
                    </svg>
                </div>
                <div className={styles.arrowRight} onClick={slideRight}>
                    <svg version="1.1" viewBox="0 0 23.335 42.426" xmlns="http://www.w3.org/2000/svg">
                        <path d="m2.1213 42.426 21.213-21.213-21.213-21.213-2.1213 2.1213 19.092 19.092-19.092 19.092z" fill="#fff" strokeWidth="0"/>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Gallery