import React, { useEffect, useContext, useState } from 'react';
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import clamp from "clamp-js";
import {ThemeContext} from "../../Components/Context/context";
import {Loader} from "../../Components/Loader/loader";
import Breadcrumbs from "../../Components/Breadcrumbs/breadcrumbs";

import translation from "../../Components/Helpers/translation";

import styles from './articlePage.module.scss'
import Footer from "../Footer/footer";

function ArticlesPage(props) {

    const [data, setData] = useState(null)
    const showContext = useContext(ThemeContext)
    let slug = require('slug')
    const { lang } = useParams();

    useEffect(() => {
        axios
            .post(`${showContext.mainUrl}article?lang=${lang}&expand=chapter.subsection` )
            .then(res => {
                setData(res.data)
            })
    },[lang, showContext.mainUrl])

    useEffect(() => {
        const tooltips = Array.from(document.getElementsByClassName("tooltipWrapper"))
        tooltips.forEach((item) => {
            item.remove()
        })

        const elements = document.getElementsByClassName(styles.text)
        Array.from(elements).forEach((element) => {
            clamp(element, {clamp: 3})
        })
    },[data])

    return (
        <>
            <Loader data={data}/>
            {data ?
                <div className={styles.wrapper}>
                    <Breadcrumbs lang={lang}/>
                    <div className={styles.subWrapper}>
                        <span className={styles.title}>{translation.articles.title}</span>
                        {data.map((item,index) => {
                            return (
                                item.published !== 0 ?
                                    <div key={index} className={styles.container}>
                                        {item.author !== null
                                            ? <Link className={styles.author} to={`/${lang}/tworcy/autorzy/${item.author.id}-${slug(`${lang === 'pl' ? `${item.author.first_name} ${item.author.last_name}` : `${item.author.first_name_en} ${item.author.last_name_en}` }`)}`} dangerouslySetInnerHTML={{__html: `${lang === 'pl' ? `${item.author.first_name} ${item.author.last_name}` : `${item.author.first_name_en} ${item.author.last_name_en}` }`}}/>
                                            : null
                                        }
                                        <span className={styles.tractateTitle} dangerouslySetInnerHTML={{__html: item.title}}/>
                                        <div className={styles.text} dangerouslySetInnerHTML={{__html: item.chapter[0] ? item.chapter[0].text : null}}/>
                                        <Link className={styles.link} to={`/${lang}/artykuly-wprowadzajace/${item.id}-${slug(item.title)}`}>{translation.articles.readAll}</Link>
                                    </div>
                                : null
                            )
                        })}
                    </div>
                    <Footer/>
                </div>
                : null
            }
        </>
    )
}

export default ArticlesPage;