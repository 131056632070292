import React, { useState, useContext, useEffect } from 'react';

import Player from "../../Components/Player/player";
import Gallery from "../../Components/Gallery/gallery";
import Breadcrumbs from "../../Components/Breadcrumbs/breadcrumbs";

import styles from './multimedia.module.scss'

import {Loader} from "../../Components/Loader/loader";
import {ThemeContext} from "../../Components/Context/context";
import axios from "axios";
import {useParams} from "react-router-dom";
import Footer from "../Footer/footer";

function Multimedia(props) {

    const [data, setData] = useState(null);
    const showContext = useContext(ThemeContext);
    const { lang, id } = useParams()

    useEffect(() => {
        axios
            .post(`${showContext.mainUrl}multimedia/search?id=${id}&lang=${lang}&expand=gallery`)
            .then(res => {
                setData(res.data)
            })
    }, [id, lang, showContext.mainUrl])

    return (
        <>
            <Loader data={data}/>
            {data ?
                <div className={styles.wrapper}>
                    <Breadcrumbs title={data.title} lang={lang}/>
                    <div className={styles.subWrapper}>
                        <div className={styles.mobileData}>
                            <span className={styles.dateMobile} dangerouslySetInnerHTML={{__html: data.date}}/>
                            <span className={styles.titleMobile} dangerouslySetInnerHTML={{__html: data.title}}/>
                            <span className={styles.textMobile} dangerouslySetInnerHTML={{__html: data.text}}/>
                            <span className={styles.placeMobile} dangerouslySetInnerHTML={{__html: data.place}}/>
                        </div>
                        <span className={styles.date} dangerouslySetInnerHTML={{__html: data.date}}/>
                        { data.video_filename ?
                            <Player data={data.video_filename} img={data.image}/>
                            : null
                        }
                        <span className={styles.title} dangerouslySetInnerHTML={{__html: data.title}}/>
                        <span className={styles.text} dangerouslySetInnerHTML={{__html: data.text}}/>
                        <span className={styles.place} dangerouslySetInnerHTML={{__html: data.place}}/>
                        <div className={styles.mobileSeparator}/>
                        <Gallery photos={data.gallery.images} title={data.title}/>
                    </div>
                    <Footer/>
                </div>
                : null
            }
        </>
    )
}

export default Multimedia;