
import { Link, useHistory } from "react-router-dom";
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import translation from "../Helpers/translation";

import styles from'./breadcrumb.module.scss'

function BreadcrumbsContainer(props) {

    const stripBrTag = (text) => {
        return text.replace("<br>", ' ')
    }

    const creatorsUrl = (url) => {
        if(url[0] === '1') {
            return translation.breadcrumb.creators.nifc
        } else if(url[0] === '2') {
            return translation.breadcrumb.creators.inst
        } else {
            return translation.breadcrumb.creators[stripBrTag(url)]
        }
    }

    const CreatorsCategory = ({ match }) => (
        <span>{creatorsUrl(match.params.category)}</span>
    );

    const CreatorsPersons = ({ match }) => (
        <span dangerouslySetInnerHTML={{__html: stripBrTag(props.title)}}/>
    );

    const MultimediaId = ({ match }) => (
        <span dangerouslySetInnerHTML={{__html: stripBrTag(props.title)}}/>
    );

    const ArticleId = ({ match }) => (
        <span dangerouslySetInnerHTML={{__html: stripBrTag(props.title)}}/>
    );

    const TractateID = ({ match }) => (
        <span dangerouslySetInnerHTML={{__html: stripBrTag(props.title)}}/>
    );

    const TractateShow = ({ match }) => (
        <span>{translation.tractates.presentation}</span>
    );

    const routes = [
        { path: '/pl', breadcrumb: translation.breadcrumb.homepage },
        { path: '/en', breadcrumb: translation.breadcrumb.homepage },
        { path: '/', breadcrumb: null},
        { path: `/${props.lang}/wyszukiwanie`, breadcrumb: translation.breadcrumb.search},
        { path: `/${props.lang}/wyszukiwanie/values`, breadcrumb: null},
        { path: `/${props.lang}/o-projekcie`, breadcrumb: translation.breadcrumb.about},
        { path: `/${props.lang}/artykuly-wprowadzajace`, breadcrumb: translation.breadcrumb.articles},
        { path: `/${props.lang}/artykuly-wprowadzajace/:id`, breadcrumb: ArticleId},
        { path: `/${props.lang}/traktaty`, breadcrumb: translation.breadcrumb.tractates},
        { path: `/${props.lang}/traktaty/:id`, breadcrumb: TractateID},
        { path: `/${props.lang}/traktaty/:id/widok-values`, breadcrumb: TractateShow},
        { path: `/${props.lang}/tworcy`, breadcrumb: translation.breadcrumb.creators.main},
        { path: `/${props.lang}/tworcy/:category`, breadcrumb: CreatorsCategory},
        { path: `/${props.lang}/tworcy/:category/:person`, breadcrumb: CreatorsPersons},
        { path: `/${props.lang}/multimedia`, breadcrumb: translation.breadcrumb.multimedia},
        { path: `/${props.lang}/multimedia/:id`, breadcrumb: MultimediaId}
    ];

    function BreadcrumbsWrapper({ breadcrumbs }) {

        let history = useHistory();
        function handleClick() {
            history.goBack()
        }

        const Breadcrumbs = ({ breadcrumbs }) => (
            <div className={styles.wrapper}>
                <div onClick={handleClick} className={styles.backWrapper}>
                    <div className={styles.back}>
                        <div className={styles.arrow}/>
                        <span className={styles.text}>{translation.breadcrumb.back}</span>
                    </div>
                </div>
                {breadcrumbs.map(({match, breadcrumb}) => (
                    breadcrumb === breadcrumbs[breadcrumbs.length -1].breadcrumb
                        ? match.path === `/${props.lang}/multimedia/:id` || match.path === `/${props.lang}/artykuly-wprowadzajace/:id`
                            ? <div key={match.url} className={`${styles.lastItem} ${styles.smaller}`}>{breadcrumb}</div>
                            : <div key={match.url} className={styles.lastItem}>{breadcrumb}</div>
                        : <div key={match.url} className={styles.breadcrumb}>
                            <Link to={match.url}>{breadcrumb}</Link>
                            <span className={styles.separator}>/</span>
                        </div>
                ))}
            </div>
        );

        return (
            <Breadcrumbs breadcrumbs={breadcrumbs}/>
        )
    }

    return withBreadcrumbs(routes)(BreadcrumbsWrapper)();
}

export default BreadcrumbsContainer;