import React, { useEffect} from 'react';
import styles from './loader.module.scss'
import './loader.module.scss'

export function Loader(props) {

    useEffect(() => {
        if(props.data && document.getElementById("loader")) {
            document.getElementById("loader").style.transition = "0.4s all"
            if(props.scroll !== false) {
                window.scrollTo(0,0)
            }
            setTimeout(() => {
                document.getElementById("loader").style.opacity = "0"
                document.getElementById("loader").style.visibility = "hidden"
            },100)
        } else {
            document.getElementById("loader").style.transition = "0s all"
            document.getElementById("loader").style.opacity = "1"
            document.getElementById("loader").style.visibility = "visable"
        }
    },[props.data, props.scroll])

    return (
        <div className={`${styles.loader} ${props.data ? styles.loaderHide : ''}`} id={"loader"}>
            <div className={styles.loaderInner}>
                <div className={`${styles.circle} ${styles.one}`}/>
                <div className={`${styles.circle} ${styles.two}`}/>
                <div className={`${styles.circle} ${styles.three}`}/>
                <div className={`${styles.circle} ${styles.four}`}/>
                <div className={`${styles.circle} ${styles.five}`}/>
            </div>
        </div>
    );
}