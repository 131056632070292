function orphans() {
    document.querySelectorAll("span").forEach((span) => {
        let tekst = span.innerHTML;
        tekst = tekst.replace(/(\s)([\S])[\s]+/g,"$1$2&nbsp;"); //jednoznakowe
        tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g,"$1$2&nbsp;"); //dwuznakowe
        tekst = tekst.replace(/(\s)([^<][\S]{2})[\s]+/g,"$1$2&nbsp;"); //trzyznakowe
        span.innerHTML = tekst ;
    });

    document.querySelectorAll("p").forEach((p) => {

        const table = [
            "quote-0-left",
            "quote-1-left",
            "quote-2-left",
            "quote-3-left",
            "quote-0-right",
            "quote-1-right",
            "quote-2-right",
            "quote-3-right",
        ]

        if(!table.includes(p.parentElement.id)) {
            let tekst = p.innerHTML;
            tekst = tekst.replace(/(\s)([\S])[\s]+/g,"$1$2&nbsp;"); //jednoznakowe
            tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g,"$1$2&nbsp;"); //dwuznakowe
            tekst = tekst.replace(/(\s)([^<][\S]{2})[\s]+/g,"$1$2&nbsp;"); //trzyznakowe
            p.innerHTML = tekst ;
        }
    });
}

export default orphans