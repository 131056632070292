import React, { useRef } from "react";

import styles from './modal.module.scss'

function Modal(props) {

    const modalBackgroundRef = useRef();
    const modalRef = useRef();
    const closeRef = useRef()

    const hideModal = (event) => {
        if(event.target === modalRef.current || event.target === closeRef.current) {
            props.setOpened(!props.opened)
        }
    }

    return (
        <>
            <div ref={modalBackgroundRef} className={`${styles.modalBackground} ${!props.opened ? styles.modalBackgroundHidden : ''}`} data-opened={props.opened} />
            <div ref={modalRef} className={`${styles.modalWrapper} ${!props.opened ? styles.modalWrapperHidden : ''}`} data-opened={props.opened} onClick={hideModal}>
                <div className={styles.modalContainer}>
                    <div className={styles.modal}>
                        <div ref={closeRef} className={styles.close} onClick={hideModal}/>
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal