import React, { useEffect, useState } from 'react';
import usePrevious from "../Helpers/hooks/usePrevious";
import useWindowSize from "../Helpers/hooks/useWindowSize";

export const ThemeContext = React.createContext({
    theme : localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light',
    mainUrl : "https://musictreatises.nifc.pl/cms/web/api/",
    versions : []
});

const ThemeContextProvider = props => {
    const [theme, setTheme] = useState(localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light');
    const [versions, setVersions] = useState([]);
    const [versionsOld, setVersionsOld] = useState([])
    const prevVersions = usePrevious(versions);
    const [mainUrl,] = useState("https://musictreatises.nifc.pl/cms/web/api/")
    const size = useWindowSize();

    useEffect(() => {
        localStorage.setItem('theme', theme)

        const root = document.getElementById('root')

        root.classList.add(theme);

        if(root.classList.length > 1) {
            root.classList.remove(root.classList[0])
        }
    },[theme])

    const updateTheme = () => {
        if(theme === "light") {
            setTheme("dark")
        } else {
            setTheme("light")
        }
    }

    const updateVersions = (table) => {
        const availableVersions = ["originalSkan","originalEdycja","polski","angielski","pdf"];
        let tmpTable = [];
        if(table.length) {
            table.forEach((item) => {
                if(availableVersions.includes((item))) {
                    tmpTable.push(item)
                }
            })
        }
        setVersions(tmpTable)
        setVersionsOld(tmpTable)
    }

    useEffect(() => {
        if(prevVersions === versions) {
            if(size.width <= 750) {
                setVersions(versions.slice(0,1))
            } else {
                setVersions(versionsOld.slice(0,2))
            }
        }
    },[size, versions, prevVersions, versionsOld])

    return(
        <ThemeContext.Provider value={{theme: theme, mainUrl: mainUrl, versions: versions, updateTheme, updateVersions}}>
            {props.children}
        </ThemeContext.Provider>
    )
}

export default ThemeContextProvider;