import React, {useContext, useEffect, useState} from 'react';
import { useLocation, useParams} from "react-router-dom";
import queryString from 'query-string';

import Breadcrumbs from "../../Components/Breadcrumbs/breadcrumbs";

import styles from './searchPage.module.scss'
import translation from "../../Components/Helpers/translation";
import axios from "axios";
import {Loader} from "../../Components/Loader/loader";
import {ThemeContext} from "../../Components/Context/context";
import Footer from "../Footer/footer";

function SearchPage(props) {

    const location = useLocation();
    let slug = require('slug')
    const showContext = useContext(ThemeContext)
    const [data, setData] = useState(null);
    const [more, setMore] = useState(false);
    const [offset, setOffset] = useState(0)
    const { lang } = useParams();

    const keyword = queryString.parse(location.search)["keyword"]
    const where = queryString.parse(location.search)["where"]

    useEffect(() => {
        axios
            .post(`${showContext.mainUrl}search/search?range=${where}&where=${keyword}&offset=${0}`)
            .then(res => {
                setData(res.data.result)
                if(res.data.result.length === 20) {
                    setMore(true)
                } else {
                    setMore(false)
                }
            })
    }, [keyword, showContext.mainUrl, where])

    useEffect(() => {
        const tooltips = Array.from(document.getElementsByClassName("tooltipWrapper"))
        tooltips.forEach((item) => {
            item.remove()
        })

        const table = Array.from(document.getElementsByClassName(styles.text))

        if(table.length) {
            table.forEach((item) => {

                function getIndicesOf(searchStr, str, caseSensitive) {
                    let searchStrLen = searchStr.length;
                    if (searchStrLen === 0) {
                        return [];
                    }
                    let startIndex = 0, index, indices = [];
                    if (!caseSensitive) {
                        str = str.toLowerCase();
                        searchStr = searchStr.toLowerCase();
                    }
                    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
                        indices.push(index + searchStr.length);
                        startIndex = index + searchStrLen;
                    }
                    return indices;
                }

                const indices = getIndicesOf(keyword, item.innerText);
                console.log(indices)

                const firstDots = () => {
                    if(indices[0] - 500 >= 0) {
                        return "..."
                    } else {
                        return ""
                    }
                }

                if(indices[indices.length - 1] > 1000) {
                    item.innerHTML = firstDots() + item.innerText.slice(indices[0] - 500 > 0 ? indices[0] - 500 : 0, indices[indices.length - 1] + 100) + "..."
                } else if(indices[indices.length - 1] > 500) {
                    item.innerHTML = firstDots() + item.innerText.slice(indices[0] - 500 > 0 ? indices[0] - 500 : 0, indices[indices.length - 1] + 500) + "..."
                } else {
                    item.innerHTML = firstDots() + item.innerText.slice(indices[0] - 500 > 0 ? indices[0] - 500 : 0, indices[indices.length - 1] + 200) + "..."
                }

                const regex = new RegExp(keyword, "gi")

                function replaceAll(string, search, replace) {
                    const text = string.slice(string.split(search)[0].length, string.split(search)[0].length + replace.length)
                    return string.split(search).join(`<strong class="${styles.superStrong}" >${text}</strong>`);
                }

                item.innerHTML = replaceAll(item.innerText, regex, `${keyword}`)
            })
        }

    },[data, keyword])

    const searchMore = () => {

        setOffset(offset + 20)
        axios
            .post(`${showContext.mainUrl}search/search?range=${where}&where=${keyword}&offset=${offset + 20}`)
            .then(res => {
                let oldData = [...data]
                oldData = [...oldData, ...res.data.result]
                setData(oldData)
                if(res.data.result.length === 20) {
                    setMore(true)
                } else {
                    setMore(false)
                }
            })
    }

    const makeLink = (item) => {
        const selectTractateView = () => {
            switch (item.lang) {
                case 'pl':
                    return "polski"
                case 'en':
                    return "angielski"
                case 'ocr':
                    return "originalEdycja"
                default:
                    return "polski"
            }
        }

        switch (item.table_name) {
            case 'articles':
                return `/${item.lang}/artykuly-wprowadzajace/${item.id}-${slug(item.title)}`
            case 'treaties':
                return `/${lang}/traktaty/${item.id}-${slug(item.title)}/widok-values?types[]=${selectTractateView()}`
            case 'translators':
                return `/${item.lang}/tworcy/tlumacze/${item.id}-${slug(item.title)}`
            case 'editors':
                return `/${item.lang}/tworcy/redaktorzy/${item.id}-${slug(item.title)}`
            case 'others':
                return `/${item.lang}/tworcy/inni/${item.id}-${slug(item.title)}`
            case 'authors':
                return `/${item.lang}/tworcy/autorzy/${item.id}-${slug(item.title)}`
            case 'multimedia':
                return `/${item.lang}/multimedia/${item.id}-${slug(item.title)}`
            case "The Fryderyk Chopin Institute" :
                return `/${item.lang}/tworcy/${item.id}-${slug(item.table_name)}}`
            case "Centre for Grieg Research" :
                return `/${item.lang}/tworcy/${item.id}-${slug(item.table_name)}}`
            default:
                return null
        }
    }

    const titleLink = (item) => {
        if (item.table_name === "The Fryderyk Chopin Institute" || item.table_name === "Centre for Grieg Research") {
            return `${translation.creators.title.main} › ${item.title}`
        } else if (item.table_name === "About the project") {
            return `${translation.menu.about}`
        } else {
            return `${translation.search.types[item.table_name]} › ${item.title}`
        }
    }

    return (
        <>
            <Loader data={data} scroll={false}/>
            {data ?
                <div className={styles.wrapper}>
                    <Breadcrumbs lang={lang}/>
                    <div className={styles.subWrapper}>
                        <div className={styles.rightTitle}>
                            <span className={styles.title}>{translation.others.search}</span>
                            <span className={styles.searchText}>{keyword}</span>
                        </div>
                        <div className={styles.bar}/>
                        {data
                            ? <span className={styles.counter}>{`${translation.others.results}: ${data.length ? `${data.length}${more ? `+` : ''}` : 0} `}</span>
                            : null
                        }
                        <div className={styles.list}>
                            { data.length > 0
                                ? data.map((item, index) => {
                                    return (
                                        <div className={styles.itemSearch} key={index}>
                                            <div className={styles.link} onClick={() =>window.location.href = makeLink(item) }>
                                                <span className={styles.titleLink} dangerouslySetInnerHTML={{__html : titleLink(item)}}/>
                                            </div>
                                            <span className={styles.text} dangerouslySetInnerHTML={{__html: item.RESULT}}/>
                                        </div>
                                    )
                                }) :null
                            }
                        </div>
                        <div className={styles.morePhotosWrapper} style={!more ? {display: "none"} : null}>
                            <div className={styles.morePhotos} id={"moreButton"}
                                 onClick={searchMore}
                            >
                                <span className={styles.more}>{translation.multimedia.morePhotos}</span>
                                <div className={`${styles.moreArrow} ${showContext.theme === 'light' ? styles.color : styles.black}`}/>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
                : null
            }
        </>
    )
}

export default SearchPage;