import React from 'react'
import styles from  '../../Containers/TraktateShow/tractateShow.module.scss'
import scrollTo from "../Helpers/scrollTo";

function TractateLink(props) {

    const slideDownUpHandler = (event, dataSet) => {
        const subMenu = document.querySelectorAll(`[data-subpoint~="${dataSet}"]`)[0]
        const arrow = document.querySelectorAll(`[data-arrow~="${dataSet}"]`)[0]

        if(subMenu.style.height === '' || subMenu.style.height === '0px') {
            subMenu.style.height = `${subMenu.scrollHeight}px`;
            arrow.classList.add(styles.pointArrowOpen)
            subMenu.addEventListener('transitionend', () => {
                subMenu.style.height = "auto"
            }, {once: true})
        } else {
            subMenu.style.height = `${subMenu.scrollHeight}px`;
            setTimeout(() => {
                subMenu.style.height = '0px'
                arrow.classList.remove(styles.pointArrowOpen)
            },1)
        }
    }

    const scrollTractates = () => {

        const left = document.getElementById("leftTractate")
        const right = document.getElementById("rightTractate")

        let leftElement = document.querySelectorAll(`[data-chapter~="left-chapter-${props.idToScroll}"]`)[0]
        let rightElement = document.querySelectorAll(`[data-chapter~="right-chapter-${props.idToScroll}"]`)[0]

        const topPosLeft = leftElement.offsetTop + 1
        scrollTo(left, topPosLeft, 600);

        if(right) {
            const topPosRight = rightElement.offsetTop + 1
            scrollTo(right, topPosRight, 600);
        }
    }

    return (
        !props.danger ?
            <div className={props.style}
                 onClick={(event) =>
                     {scrollTractates();
                         if (props.subchapter !== 0 && props.type === "chapter") {
                             slideDownUpHandler(event, props.idToScroll)
                         }
                     }
                 }
                 data-spis={`chapter-${props.idToScroll}`}
            >
                {props.children}
            </div>
        :
            <div className={props.style}
                 onClick={(event) =>
                     {scrollTractates();
                         if (props.subchapter !== 0 && props.type === "chapter") {
                             slideDownUpHandler(event, props.idToScroll)
                         }
                     }
                 }
                 data-spis={`chapter-${props.idToScroll}`}
                 dangerouslySetInnerHTML={{__html: props.danger}}
            />
    )
}

export default TractateLink