import React, { useEffect, useState, useContext } from 'react';
import axios from "axios";
import {ThemeContext} from "../../Components/Context/context";
import {Loader} from "../../Components/Loader/loader";
import Breadcrumbs from "../../Components/Breadcrumbs/breadcrumbs";

import styles from './aboutPage.module.scss'
import {useParams} from "react-router-dom";
import Footer from "../Footer/footer";
import useWindowSize from "../../Components/Helpers/hooks/useWindowSize";

function AboutPage(props) {

    const showContext = useContext(ThemeContext);
    const [data, setData] = useState(null);
    const {lang} = useParams();

    const size = useWindowSize()

    useEffect(() => {
        axios
            .post(`${showContext.mainUrl}text-pages/search?id=3&lang=${lang}`)
            .then(res => {
                setData(res.data)
            })
    }, [lang, showContext.mainUrl])

    useEffect(() => {
        document.querySelectorAll("p").forEach((p) => {
            if(size.width < 1000 && size.width > 600) {
                if(p.style.paddingLeft) {
                    p.style.paddingLeft = "30px"
                }
            } else if (size.width <= 600) {
                if(p.style.paddingLeft) {
                    p.style.paddingLeft = "20px"
                }
            } else {
                if(p.style.paddingLeft) {
                    p.style.paddingLeft = "40px"
                }
            }
        });
    },[size.width, data])

    return (
        <>
            <Loader data={data}/>
            {data ?
                <div className={styles.wrapper}>
                    <div className={styles.subWrapper}>
                        <Breadcrumbs lang={lang}/>
                        <div className={styles.subSubWrapper} dangerouslySetInnerHTML={{__html: data.text}}/>
                    </div>
                    <Footer/>
                </div>
                : null
            }
        </>
    )
}

export default AboutPage;