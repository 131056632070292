import React, { useContext, useState, useEffect } from 'react';
import axios from "axios";
import { Link, useParams} from "react-router-dom";
import {ThemeContext} from "../../Components/Context/context";
import useWindowSize from "../../Components/Helpers/hooks/useWindowSize";
import Breadcrumbs from "../../Components/Breadcrumbs/breadcrumbs";
import { Loader } from "../../Components/Loader/loader";

import translation from "../../Components/Helpers/translation";

import styles from './tractatePage.module.scss'
import traktaty from "../../assets/images/tractates/traktaty.png";
import Footer from "../Footer/footer";


function TractatePage(props) {

    const [data, setData] = useState(null)
    const size = useWindowSize();
    const showContext = useContext(ThemeContext)
    let slug = require('slug')
    const { lang } = useParams();

    const stripHTMLTag = (text) => {
        let tmpText = text.replace("<br>", ' ')
        tmpText = tmpText.replace("<i>", '')
        tmpText = tmpText.replace("</i>", '')
        return tmpText
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        axios
            .post(`${showContext.mainUrl}treaty?expand=contentstable.chapter.subchapter.pages` )
            .then(res => {
                setData(res.data)
                // console.log(res.data)
            })
    },[showContext.mainUrl])

    return (
        <>
            <Loader data={data}/>
            {data ?
                <div className={styles.wrapper}>
                    <Breadcrumbs lang={lang}/>
                    <div className={styles.subWrapper}>
                        { size.width > 1350
                            ? <div className={styles.polygon}>
                                <div className={styles.imageWrapper}>
                                    <img src={traktaty} alt={''}/>
                                </div>
                            </div>
                            : null
                        }
                        <div className={styles.list}>
                            {size.width <= 1350
                                ? <div className={styles.polygon}>
                                    <div className={`${styles.imageWrapper} ${styles.noMargin}`}>
                                        <img src={traktaty} alt={''}/>
                                    </div>
                                </div>
                                : null
                            }
                            <span className={`${styles.title} ${styles.center} ${styles.noMarginTitle}`}>{translation.tractates.title}</span>
                            {data.map((item, index) => {
                                return (
                                    item.published !== 0 ?
                                        <div key={index} className={styles.container}>
                                            <img className={styles.image} src={item.miniature} alt={`${item.title_ocr}-${translation.tractates.minature}`}/>
                                            <div className={styles.right}>
                                                {/* {console.log(stripHTMLTag(item.title_ocr))} */}
                                                <span className={styles.author} dangerouslySetInnerHTML={{__html: `${lang === "pl" ? `${item.author[0].first_name} ${item.author[0].last_name}` : `${item.author[0].first_name_en} ${item.author[0].last_name_en}`}`}}/>
                                                <span className={styles.years} dangerouslySetInnerHTML={{__html: item.first_published_year}}/>
                                                <span className={styles.tractateTitle} dangerouslySetInnerHTML={{__html: `${lang === "pl" ? item.title_ocr : item.title_ocr_en }`}}/>
                                                <Link className={styles.link} to={`/${lang}/traktaty/${item.id}-${slug(stripHTMLTag(item.title_ocr))}`}>{translation.tractates.open}</Link>
                                            </div>
                                        </div>
                                    : null
                                )
                            })}
                        </div>
                    </div>
                    <Footer/>
                </div>
                : null
            }
        </>
    )
}

export default TractatePage;