import React, {useContext, useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import clamp from "clamp-js"

import useWindowSize from "../../Components/Helpers/hooks/useWindowSize";

import translation from "../../Components/Helpers/translation";

import styles from	'./mainPage.module.scss'

import {Loader} from "../../Components/Loader/loader";
import axios from "axios";
import {ThemeContext} from "../../Components/Context/context";
import Footer from "../Footer/footer";

function MainPage() {

    const size = useWindowSize();
    const { lang } = useParams();

    const showContext = useContext(ThemeContext)
    const [data, setData] = useState(null);
    const [treaty, setTreaty] = useState([])
    let slug = require('slug')


    useEffect(() => {
        const elements = document.getElementsByClassName(styles.text)
        if(size.width > 700) {
            Array.from(elements).forEach((element) => {
                clamp(element, {clamp: 8})
            })
        } else if(size.width <= 700) {
            Array.from(elements).forEach((element) => {
                clamp(element, {clamp: 6})
            })
        }

    },[size.width, data])

    useEffect(() => {
        axios
            .post(`${showContext.mainUrl}text-pages/main-page?lang=${lang}`)
            .then(res => {
                setData(res.data)
            })
    }, [lang, showContext.mainUrl])

    useEffect(() => {
        const tooltips = Array.from(document.getElementsByClassName("tooltipWrapper"))
        tooltips.forEach((item) => {
            item.remove()
        })
    })

    useEffect(() => {
        if(data) {

            let tmpTrevState = data.treaties

            tmpTrevState.sort(function(a, b){return a.id - b.id});

            setTreaty(tmpTrevState)
        }
    },[data])

    const selectMultimediaStyle = (index) => {
        if(data.multimedia.length === 2) {
            return styles.multimediaWrapper2
        } else if(data.multimedia.length % 4 === 0 && data.multimedia.length === 4) {
            if(index === data.multimedia.length -1) {
                return styles.multimediaWrapper4
            } else {
                return ''
            }
        } else if(data.multimedia.length % 5 === 0 && data.multimedia.length === 5) {
            if(index === data.multimedia.length -1 || index === data.multimedia.length -2) {
                return styles.multimediaWrapper5
            } else {
                return ''
            }
        } else if(data.multimedia.length % 7 === 0 && data.multimedia.length === 7) {
            if(index === data.multimedia.length - 1) {
                return styles.multimediaWrapper7
            } else {
                return ''
            }
        } else if(data.multimedia.length % 8 === 0 && data.multimedia.length === 8) {
            if(index === data.multimedia.length -1 || index === data.multimedia.length -2) {
                return styles.multimediaWrapper8
            } else {
                return ''
            }
        } else {
            return ''
        }
    }

    const stripHTMLTag = (text) => {
        let tmpText = text.replace("<br>", ' ')
        tmpText = tmpText.replace("<i>", '')
        tmpText = tmpText.replace("</i>", '')
        return tmpText
    }


    return (
        <>
            <Loader data={data}/>
            {data ?
                <div className={styles.mainWrapper}>
                    <div className={styles.subWrapper}>
                        <span className={styles.mainText} dangerouslySetInnerHTML={{__html: translation.others.mainPageText}}/>
                        <div className={styles.tractatesWrapper}>
                            {treaty.map((item, index) => {
                                return (
                                    <div className={styles.containerWrapper} key={index}>
                                        <div className={styles.container}>
                                            <Link className={styles.author} to={`/${lang}/tworcy/autorzy/${item.id_author}-${slug(item.author)}`}>
                                                <span dangerouslySetInnerHTML={{__html: item.author}}/>
                                            </Link>
                                            <span className={styles.year} dangerouslySetInnerHTML={{__html: `${item.first_published_year}`}}/>
                                            <Link className={styles.title} to={`/${lang}/traktaty/${item.id}-${slug(stripHTMLTag(item.title_ocr))}`}>
                                                <span className={styles.title} dangerouslySetInnerHTML={{__html: `${item.title_ocr}`}}/>
                                            </Link>
                                        </div>
                                        {data.treaties.length - 1 !== index
                                            ? <div className={styles.separator}/>
                                            : null
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        <div className={styles.articlesWrapper}>
                            <div className={`${styles.container} ${styles.containerArticles}`}>
                                {data.articles.map((item, index) => {
                                    return (
                                        <div className={styles.articleWrapper} key={index}>
                                            {item.author !== null
                                                ? <Link className={styles.author} to={`/${lang}/tworcy/autorzy/${item.id_author}-${slug(item.author)}`} dangerouslySetInnerHTML={{__html: item.author}}/>
                                                : null
                                            }
                                            <span className={styles.title} dangerouslySetInnerHTML={{__html: item.title}}/>
                                            <div className={styles.text} dangerouslySetInnerHTML={{__html :item.intro}}/>
                                            <Link to={`/${lang}/artykuly-wprowadzajace/${item.id}-${slug(item.title)}`}>
                                                {translation.homePage.readAll}
                                            </Link>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={`${styles.container} ${styles.containerMultimedia}`}>
                                {data.multimedia.map((item, index) => {
                                    return (
                                        <div className={`${styles.multimediaWrapper} ${selectMultimediaStyle(index)}`} key={index}>
                                            <div className={styles.image} style={{backgroundImage: `url("${item.image}")`}}/>
                                            <span className={styles.date}>
                                                {item.date}
                                            </span>
                                            <span className={styles.title}>
                                                {item.title}
                                            </span>
                                            <Link to={`/${lang}/multimedia/${item.id}-${slug(item.title)}`}>
                                                {translation.homePage.seeRelation}
                                            </Link>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
                : null
            }
        </>
    );
}

export default MainPage;