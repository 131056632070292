import React, { useContext, useState, useEffect } from 'react';
import {ThemeContext} from "../../Components/Context/context";
import {Link, useParams} from "react-router-dom";
import Breadcrumbs from "../../Components/Breadcrumbs/breadcrumbs";
import translation from "../../Components/Helpers/translation";

import styles from './multimediaPage.module.scss'

import axios from "axios";
import {Loader} from "../../Components/Loader/loader";
import Footer from "../Footer/footer";

function MultimediaPage(props) {

    const { lang } = useParams();
    const [data, setData] = useState(null);
    let slug = require('slug')
    const showContext = useContext(ThemeContext)

    useEffect(() => {
        axios
            .post(`${showContext.mainUrl}multimedia?lang=${lang}&expand=gallery`)
            .then(res => {
                setData(res.data)
            })
    }, [lang, showContext.mainUrl])

    return (
        <>
            <Loader data={data}/>
            {data ?
                <div className={styles.wrapper}>
                    <Breadcrumbs lang={lang}/>
                    <div className={styles.subWrapper}>
                        <span className={styles.title}>{translation.multimedia.title}</span>
                        {data.map((item, index) => {
                            return (
                                <div key={index} className={styles.container}>
                                    <div className={styles.image} style={{backgroundImage: `url("${item.gallery.images[0].filename}")`}}/>
                                    <div className={styles.right}>
                                        <span className={styles.date} dangerouslySetInnerHTML={{__html: item.date}}/>
                                        <span className={styles.multimediaTitle}
                                              dangerouslySetInnerHTML={{__html: item.title}}/>
                                        <span className={styles.place} dangerouslySetInnerHTML={{__html: item.place}}/>
                                        <Link className={styles.more}
                                              to={`/${lang}/multimedia/${item.id}-${slug(item.title)}`}>{translation.multimedia.seeMultimedia}</Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <Footer/>
                </div>
                : null
            }
        </>
    )
}

export default MultimediaPage;