import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from "axios";
import smoothScroll from 'smoothscroll-polyfill';
import { Link } from "react-scroll";
import {Loader} from "../../Components/Loader/loader";
import useWindowSize from "../../Components/Helpers/hooks/useWindowSize";
import Breadcrumbs from "../../Components/Breadcrumbs/breadcrumbs";
import {ThemeContext} from "../../Components/Context/context";
import slideDownUp from "../../Components/Helpers/slideDownUp";
import {useParams} from "react-router-dom";
import StandardLink from "../../Components/Helpers/standardLink";

import styles from './article.module.scss'
import translation from "../../Components/Helpers/translation";
import Footer from "../Footer/footer";

function Article(props) {

    const { lang, id } = useParams();
    const [data, setData] = useState(null);
    const [descriptionActive,setDescriptionActive] = useState(false);
    const showContext = useContext(ThemeContext);
    const size = useWindowSize();
    const spisWrapper = useRef();
    let slug = require('slug')
    smoothScroll.polyfill();

    const slideDownUpHandler = (event, dataSet) => {
        const subMenu = document.querySelectorAll(`[data-subpoint~="${dataSet}"]`)[0]
        const arrow = document.querySelectorAll(`[data-arrow~="${dataSet}"]`)[0]

        if(subMenu.style.height === '' || subMenu.style.height === '0px') {
            subMenu.style.height = `${subMenu.scrollHeight}px`;
            arrow.classList.add(styles.pointArrowOpen)
        } else {
            subMenu.style.height = '0px'
            arrow.classList.remove(styles.pointArrowOpen)
        }
    }

    const openHandlerPhone = () => {
        let button = document.getElementById("btn-spis-phone")
        if(button.classList.contains(styles.active)) {
            button.classList.add(styles.notActive);
            button.classList.remove(styles.active)
        } else {
            button.classList.remove(styles.notActive)
            button.classList.add(styles.active)
        }
    }

    const openHandlerMobile = () => {
        const div = document.getElementsByClassName(styles.contentsWrapper)[0]
        let button = document.getElementById("btn-spis-mobile")
        if(div.classList.contains(styles.contentsWrapperActive)) {
            div.classList.remove(styles.contentsWrapperActive)
            button.classList.add(styles.notActive);
            button.classList.remove(styles.active)
        } else {
            div.classList.add(styles.contentsWrapperActive)
            button.classList.remove(styles.notActive)
            button.classList.add(styles.active)
        }
    }

    const descriptionSet = () => {
        const sups = document.querySelectorAll('[data-ancorWrapper]')

        let tableDesc = []
        let counter = 1
        sups.forEach((item,index) => {
            if(item.children.length > 0) {
                tableDesc.push({
                    number: counter,
                    description : item.children[1].innerHTML
                })
                counter++
                position_tooltip(item)
            }
        })

        return (
            tableDesc.map((item,index) => {
                return (
                    <p key={index} id={`${item.number}-description`}>
                        <a href={`#${item.number}-ancor`} data-ancor={`${item.number}-destination`} data-destination={`${item.number}-ancor`}>{item.number}.</a>
                        <span dangerouslySetInnerHTML={{__html: item.description}}/>
                    </p>
                )
            })
        )
    }

    function position_tooltip(item){

        item.children[1].style.top = `${item.getBoundingClientRect().y - item.parentElement.getBoundingClientRect().y - item.children[1].getBoundingClientRect().height - 3}px`
        const left = item.getBoundingClientRect().x - item.parentElement.getBoundingClientRect().x - item.children[1].getBoundingClientRect().width / 2 + item.getBoundingClientRect().width / 2

        if(left < 0) {
            item.children[1].style.left = 0
        } else if(item.parentElement.getBoundingClientRect().width - left - item.children[1].getBoundingClientRect().width < 0) {
            item.children[1].style.left = item.parentElement.getBoundingClientRect().width - item.children[1].getBoundingClientRect().width + "px"
        } else {
            item.children[1].style.left = `${left}px`
        }
    }

    useEffect(() => {
        axios
            .post(`${showContext.mainUrl}article/search?id=${parseInt(id)}&lang=${lang}&expand=chapter.subsection,author` )
            .then(res => {
                setData(res.data)
            })

    },[id, lang, showContext.mainUrl])

    useEffect(() => {
        if(data && descriptionActive) {
            const sups = document.querySelectorAll('[data-ancor]')

            Array.from(sups).forEach((item)=> {
                item.addEventListener("click" ,(e) => {
                    e.preventDefault();

                    document.querySelectorAll(`[data-ancor~="${e.target.dataset.destination}"]`)[0].scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
                })
            })
        }
    },[data, descriptionActive])

    useEffect(() => {
        if(data) {
            const sups = document.querySelectorAll('[data-ancorWrapper]');
            if(sups.length) {
                let value = false
                let counter = 1
                sups.forEach((item,index) => {
                    if(item.children.length > 0) {
                        if(item.children[0].classList.contains("tooltip")) {
                            item.innerHTML = `<a id="${counter}-ancor" href="#${counter}-description" data-ancor="${counter}-ancor" data-destination="${counter}-destination">${counter}</a> <div>${item.innerHTML}</div>`
                        }
                        counter++
                        item.classList.remove("tooltipWrapper")
                        item.classList.add(styles.tooltipWrapper)
                        item.children[1].classList.add(styles.tooltip)
                        value = true
                    } else {
                        item.remove()
                    }
                })

                if(value) {
                    setDescriptionActive(true)
                }
            }
        }
    },[data])

    useEffect(() => {
        if(data) {
            if(size.width > 1145) {
                const contents = document.getElementsByClassName(styles.contents)[0]
                let top = (size.height - contents.clientHeight) / 2;
                if(top < 50) {
                    contents.style.top = `${top}px`
                } else {
                    contents.style.top = "50px"
                }
            }
            if(size.width > 1145 || size.width < 450) {
                const contentsWrapper = document.getElementsByClassName(styles.contentsWrapper)[0];
                let button = document.getElementById("btn-spis-mobile")
                button.classList.add(styles.notActive);
                button.classList.remove(styles.active)
                contentsWrapper.classList.remove(styles.contentsWrapperActive)
            }
        }
    },[size,data])

    const spis = () => {
        return (
            <>
                {data.chapter.map((item, index) => {
                    return (
                        <div className={styles.pointWrapper} key={index}>
                            <Link to={`chapter-${index}`} spy={true}
                                  activeClass={`${showContext.theme === 'light' ? styles.pointContentActive : styles.pointContentActiveDark}`}
                                  smooth={true} isDynamic={true} offset={size.width <= 450 ? -72 : 0}
                                  className={styles.pointContent}
                                  onClick={(event) => {
                                      if (item.subsection.length) {
                                          slideDownUpHandler(event, index)
                                      }
                                  }}
                            >
                                <p className={styles.pointParagraph}>
                            <span className={styles.pointText}
                                  dangerouslySetInnerHTML={{__html: item.section_title}}/>
                                    {item.subsection.length
                                        ? <span className={styles.pointArrow} data-arrow={index}>►</span>
                                        : null
                                    }
                                </p>

                            </Link>
                            {item.subsection.length ?
                                <div className={styles.subPoints} data-subpoint={index}>
                                    {item.subsection.map((subItem, subIndex) => {
                                        return (
                                            <Link to={`subChapter-${index}-${subIndex}`} spy={true}
                                                  activeClass={`${showContext.theme === 'light' ? styles.subPointActive : styles.subPointActiveDark}`}
                                                  smooth={true} isDynamic={true} offset={size.width <= 450 ? -72 : 0}
                                                  key={subIndex} className={styles.subPoint}
                                                  dangerouslySetInnerHTML={{__html: subItem.subsection_title}}
                                            />
                                        )
                                    })}
                                </div>
                                : null}
                        </div>
                    )
                })}
                <div className={styles.pointWrapper} style={{ display : `${descriptionActive ? "flex" : "none"}`}}>
                    <Link to={`chapter-desc`} spy={true}
                          activeClass={`${showContext.theme === 'light' ? styles.pointContentActive : styles.pointContentActiveDark}`}
                          smooth={true} isDynamic={true} offset={size.width <= 450 ? -72 : 0}
                          className={styles.pointContent}
                    >
                        <p className={styles.pointParagraph}>
                            <span className={styles.pointText}>{translation.articles.description}</span>
                        </p>
                    </Link>
                </div>
            </>
        )
    }

    return (
        <>
            <Loader data={data}/>
            {data ?
                <div className={styles.wrapper}>
                    <Breadcrumbs title={data.title} lang={lang}/>
                    <div className={styles.subWrapper} id={"subWrapper"}>
                        <div className={styles.content}>
                            {data.author !== null
                                ? <StandardLink className={styles.author} to={`/${lang}/tworcy/autorzy/${data.author.id}-${slug(`${data.author.first_name} ${data.author.last_name}`)}`} dangerouslySetInnerHTML={`${lang === "pl" ? `${data.author.first_name} ${data.author.last_name}` : `${data.author.first_name_en} ${data.author.last_name_en}`}`}/>
                                : null
                            }
                            <div className={styles.title} dangerouslySetInnerHTML={{__html: data.title}}/>
                            {size.width <= 450 ?
                                <div className={styles.stickyWrapper}>
                                    <div className={styles.phoneContentsWrapper}>
                                        <div className={styles.contentsOpen} onClick={() => {openHandlerPhone(); slideDownUp(spisWrapper.current, styles.spisWrapperOpen)}}>
                                            <p className={styles.spis}>{translation.articles.spis}</p>
                                            <div className={`${styles.btn} ${styles.notActive}`} id={"btn-spis-phone"}>
                                                <span/>
                                                <span/>
                                                <span/>
                                            </div>
                                        </div>
                                        <div className={styles.spisWrapper} ref={spisWrapper}>
                                            {spis()}
                                        </div>
                                    </div>
                                </div>

                                : null
                            }
                            {data.chapter.map((item,index)=> {
                                return(
                                    <div className={styles.chapter} key={index} id={`chapter-${index}`}>
                                        <div className={styles.chapterTitle} dangerouslySetInnerHTML={{__html: item.section_title}}/>
                                        <div className={styles.chapterContent} dangerouslySetInnerHTML={{__html: item.text}}/>
                                        {item.subsection.map((subItem, subIndex) => {
                                            return (
                                                <div className={styles.subSection} key={subIndex} id={`subChapter-${index}-${subIndex}`}>
                                                    <div className={styles.subSectionTitle} dangerouslySetInnerHTML={{__html: subItem.subsection_title}}/>
                                                    <div className={styles.subSectionContent} dangerouslySetInnerHTML={{__html: subItem.text}}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                            <div className={styles.bibliography} id={`chapter-desc`} style={{ display : `${descriptionActive ? "flex" : "none"}`}}>
                                {data
                                    ? descriptionSet()
                                    : null
                                }
                            </div>
                        </div>
                        <div className={styles.mobileContents}>
                            <div className={styles.contentsWrapper}>
                                <div className={styles.contentsOpen} onClick={() => {openHandlerMobile()}}>
                                    <div className={`${styles.btn} ${styles.notActive}`} id={"btn-spis-mobile"}>
                                        <span/>
                                        <span/>
                                        <span/>
                                    </div>
                                </div>
                                <div className={styles.contents}>
                                    {size.width > 450 ? spis() : null }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            : null}
        </>

    )
}

export default Article;