import React from 'react'
import { Link } from "react-scroll";

function CustomLink (props) {

    return (
        <Link
            to={props.to}
            spy={props.spy}
            activeClass={props.activeClass}
            smooth={props.smooth}
            isDynamic={props.isDynamic}
            offset={props.offset}
            className={props.className}
            onClick={props.onClick}
        >
            {props.children}
        </Link>
    )
}

export default CustomLink;