import React, { useContext, useEffect, useRef, useState } from 'react';
import translation from "../../Components/Helpers/translation";
import Breadcrumbs from "../../Components/Breadcrumbs/breadcrumbs";
import { ThemeContext } from "../../Components/Context/context";

import styles from './tractateShow.module.scss'
import { Link, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import TractateSelect from "../../Components/TractateSelect/tractateSelect";
import useWindowSize from "../../Components/Helpers/hooks/useWindowSize";
import CustomLink from "../../Components/Helpers/customLink";
import smoothScroll from "smoothscroll-polyfill";
import slideDownUp from "../../Components/Helpers/slideDownUp";
import { InView } from 'react-intersection-observer';

import zoom_black from '../../assets/images/tractates/zoom_black.png'
import zoom_white from '../../assets/images/tractates/zoom_white.png'
import unZoom_black from '../../assets/images/tractates/unzoom_black.png'
import unZoom_white from '../../assets/images/tractates/unzoom_white.png'
import quote_black from '../../assets/images/tractates/quote_black.png'
import quote_white from '../../assets/images/tractates/quote_white.png'
import download_black from '../../assets/images/tractates/download_black.png'
import download_white from '../../assets/images/tractates/download_white.png'
import axios from "axios";
import { Loader } from "../../Components/Loader/loader";
import TractateLink from "../../Components/TractateLink/tractateLink";
import scrollTo from "../../Components/Helpers/scrollTo";
import usePrevious from "../../Components/Helpers/hooks/usePrevious";
import Footer from "../Footer/footer";
import Modal from "../../Components/Modal/modal";
import ClipboardJS from "clipboard";

function TractateShow() {

    const location = useLocation();
    const { id } = useParams();
    const [types, setTypes] = useState([]);
    const [data, setData] = useState(null)
    const [descriptionActiveLeft, setDescriptionActiveLeft] = useState(false);
    const [descriptionActiveRight, setDescriptionActiveRight] = useState(false);
    const [leftPhotoUrls, setLeftPhotoUrls] = useState([])
    const [rightPhotoUrls, setRightPhotoUrls] = useState([])
    const [openedModal1, setOpenedModal1] = useState(false)
    const [openedModal2, setOpenedModal2] = useState(false)
    const [queryParams,] = useState(queryString.parse(location.search)["types[]"]);
    const showContextRef = useRef(useContext(ThemeContext));
    const showContext = useContext(ThemeContext);
    const size = useWindowSize()
    const prevSize = usePrevious(size)
    const spisWrapper = useRef();
    let slug = require('slug')
    smoothScroll.polyfill();
    const { lang } = useParams();

    const translateToEndpoint = (page) => {
        switch (page) {
            case 'polski':
                return 'text'
            case 'angielski':
                return 'text_en'
            case 'originalEdycja':
                return 'text_ocr'
            default:
                return 'text_pl'
        }
    }

    const createQuotes = (type, side) => {

        let quotes = [data.quote_1, data.quote_2, data.quote_3, data.quote_4]

        switch (type) {
            case 'polski':
                quotes = [data.quote_1, data.quote_2, data.quote_3, data.quote_4]
                break;
            case 'angielski':
                quotes = [data.quote_1_en, data.quote_2_en, data.quote_3_en, data.quote_4_en]
                break;
            case 'originalEdycja':
                quotes = [data.quote_1_ocr, data.quote_2_ocr, data.quote_3_ocr, data.quote_4_ocr]
                break;
            case 'originalSkan':
                quotes = [data.quote_1_scan, data.quote_2_scan, data.quote_3_scan, data.quote_4_scan]
                break;
            default:
                break;
        }


        const copy = (event) => {

            if (!event.target.classList.contains(styles.before)) {
                event.target.getElementsByClassName(styles.before)[0].classList.add(styles.beforeShow)
                setTimeout(() => {
                    event.target.getElementsByClassName(styles.before)[0].classList.remove(styles.beforeShow)
                }, 800)
            }
        }

        return (
            <>
                <div className={styles.quoteMainTitle}>
                    {translation.quotes.title}
                </div>
                {quotes.map((item, index) => {
                    return (
                        item ?
                            <div className={styles.quoteWrapper} key={index}>
                                <div className={styles.title}>
                                    {translation.quotes[index + 1]}
                                </div>
                                <div className={styles.quoteContainer}>
                                    <div id={`quote-${index}-${side}`} className={styles.quote} dangerouslySetInnerHTML={{ __html: item }} />
                                    <div className={styles.copy} data-clipboard-target={`#quote-${index}-${side}`} onClick={copy}>
                                        {translation.quotes.copy}
                                        <div className={styles.before}>
                                            {translation.quotes.copyed}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    )
                })}
            </>
        )
    }

    const zoom = (containerID) => {
        const container = document.getElementById(containerID)
        if (container.dataset.tractatetype === "originalSkan") {
            container.children[0].style.width = `${container.dataset.zoom}%`
            container.dataset.zoom = `${parseInt(container.dataset.zoom) + 10}`
        } else {
            if (parseInt(container.dataset.zoom) < 150) {
                container.dataset.zoom = `${parseInt(container.dataset.zoom) + 10}`
                const paragraphs = Array.from(container.getElementsByTagName('p'))
                const spans = Array.from(container.getElementsByTagName('span'))
                const h1s = Array.from(container.getElementsByTagName('h1'))
                const h2s = Array.from(container.getElementsByTagName('h2'))
                const h3s = Array.from(container.getElementsByTagName('h3'))
                const h4s = Array.from(container.getElementsByTagName('h4'))
                const a = Array.from(container.getElementsByTagName('a'))

                let table = paragraphs.concat(a, h1s, h2s, h3s, h4s, spans)

                table.forEach((item => {
                    const style = window.getComputedStyle(item);
                    const fontSize = parseFloat(style.getPropertyValue('font-size').slice(0, -2))
                    const lineHeight = parseFloat(style.getPropertyValue('line-height').slice(0, -2))

                    if (!item.dataset.fontsize) {
                        item.dataset.fontsize = fontSize.toString()
                        item.dataset.lineheight = lineHeight.toString()
                        item.style.fontSize = `${fontSize * 10000 * container.dataset.zoom / 1000000}px`
                        item.style.lineHeight = `${lineHeight * 10000 * container.dataset.zoom / 1000000}px`
                    } else {
                        item.style.fontSize = `${item.dataset.fontsize * 10000 * container.dataset.zoom / 1000000}px`
                        item.style.lineHeight = `${item.dataset.lineheight * 10000 * container.dataset.zoom / 1000000}px`
                    }
                }))
            }
        }
        const sups = container.querySelectorAll('[data-ancorWrapper]')
        sups.forEach((item, index) => {
            if (item.children.length > 0) {
                position_tooltip(item)
            }
        })
    }

    const unZoom = (containerID) => {
        const container = document.getElementById(containerID)
        if (parseInt(container.dataset.zoom) > 20) {
            if (container.dataset.tractatetype === "originalSkan") {
                container.dataset.zoom = `${parseInt(container.dataset.zoom) - 10}`
                container.children[0].style.width = `${container.dataset.zoom}%`
            } else {
                if (parseInt(container.dataset.zoom) > 50) {
                    container.dataset.zoom = `${parseInt(container.dataset.zoom) - 10}`
                    const paragraphs = Array.from(container.getElementsByTagName('p'))
                    const spans = Array.from(container.getElementsByTagName('span'))
                    const h1s = Array.from(container.getElementsByTagName('h1'))
                    const h2s = Array.from(container.getElementsByTagName('h2'))
                    const h3s = Array.from(container.getElementsByTagName('h3'))
                    const h4s = Array.from(container.getElementsByTagName('h4'))
                    const a = Array.from(container.getElementsByTagName('a'))

                    let table = paragraphs.concat(a, h1s, h2s, h3s, h4s, spans)

                    table.forEach((item => {
                        const style = window.getComputedStyle(item);
                        const fontSize = parseFloat(style.getPropertyValue('font-size').slice(0, -2))
                        const lineHeight = parseFloat(style.getPropertyValue('line-height').slice(0, -2))

                        if (!item.dataset.fontsize) {
                            item.dataset.fontsize = fontSize.toString()
                            item.dataset.lineheight = lineHeight.toString()
                            item.style.fontSize = `${fontSize * 10000 * container.dataset.zoom / 1000000}px`
                            item.style.lineHeight = `${lineHeight * 10000 * container.dataset.zoom / 1000000}px`
                        } else {
                            item.style.fontSize = `${item.dataset.fontsize * 10000 * container.dataset.zoom / 1000000}px`
                            item.style.lineHeight = `${item.dataset.lineheight * 10000 * container.dataset.zoom / 1000000}px`
                        }
                    }))
                }
            }
        }
        const sups = container.querySelectorAll('[data-ancorWrapper]')
        sups.forEach((item, index) => {
            if (item.children.length > 0) {
                position_tooltip(item)
            }
        })
    }

    const slideDownUpHandler = (event, dataSet) => {
        const subMenu = document.querySelectorAll(`[data-subpoint~="${dataSet}"]`)[0]
        const arrow = document.querySelectorAll(`[data-arrow~="${dataSet}"]`)[0]

        if (subMenu.style.height === '' || subMenu.style.height === '0px') {
            subMenu.style.height = `${subMenu.scrollHeight}px`;
            arrow.classList.add(styles.pointArrowOpen)
        } else {
            subMenu.style.height = '0px'
            arrow.classList.remove(styles.pointArrowOpen)
        }
    }

    const openHandlerPhone = () => {
        let button = document.getElementById("btn-spis-phone")
        if (button.classList.contains(styles.active)) {
            button.classList.add(styles.notActive);
            button.classList.remove(styles.active)
        } else {
            button.classList.remove(styles.notActive)
            button.classList.add(styles.active)
        }
    }

    const openHandlerMobile = () => {
        const div = document.getElementsByClassName(styles.contentsWrapper)[0]
        let button = document.getElementById("btn-spis-mobile")
        if (div.classList.contains(styles.contentsWrapperActive)) {
            div.classList.remove(styles.contentsWrapperActive)
            button.classList.add(styles.notActive);
            button.classList.remove(styles.active)
        } else {
            div.classList.add(styles.contentsWrapperActive)
            button.classList.remove(styles.notActive)
            button.classList.add(styles.active)
        }
    }

    const onScrollUpdate = (entry, inView, side, subPoint = false) => {
        const { target } = entry;

        const menuItem = document.querySelector(`[data-spis="${target.dataset.chapter.replace(`${side}-`, "")}"]`);
        if (menuItem) {
            if (inView) {
                if (subPoint) {
                    menuItem.classList.add(styles.subPointActive)
                } else {
                    menuItem.classList.add(styles.pointContentActive)
                }
            } else {
                if (subPoint) {
                    menuItem.classList.remove(styles.subPointActive)
                } else {
                    menuItem.classList.remove(styles.pointContentActive)
                }
            }
        }
    };

    const spis = () => {
        return (
            <>
                {size.width > 450
                    ? data.treatychapter.map((chapterItem, chapterIndex) => {
                        return (
                            <div className={styles.pointWrapper} key={chapterIndex}>
                                <TractateLink type={"chapter"} idToScroll={chapterIndex} style={styles.pointContent} subchapter={chapterItem.subsection.length}>
                                    <p className={styles.pointParagraph}>
                                        <span className={styles.pointText} dangerouslySetInnerHTML={{ __html: `${lang === "en" ? chapterItem.section_title_en : chapterItem.section_title}` }} />
                                        {chapterItem.subsection.length > 0
                                            ? <span className={styles.pointArrow} data-arrow={chapterIndex}>►</span>
                                            : null
                                        }
                                    </p>
                                </TractateLink>
                                {chapterItem.subsection.length > 0 ?
                                    <div className={styles.subPoints} data-subpoint={chapterIndex}>
                                        {chapterItem.subsection.map((subChapterItem, subChapterIndex) => {
                                            return (
                                                <TractateLink key={`${chapterIndex}.${subChapterIndex}`} type={"subChapter"} idToScroll={`${chapterIndex}.${subChapterIndex}`} style={styles.subPoint} danger={`${lang === "en" ? subChapterItem.subsection_title_en : subChapterItem.subsection_title}`}>

                                                </TractateLink>
                                            )
                                        })}
                                    </div>
                                    : null}
                            </div>
                        )
                    })
                    : data.treatychapter.map((chapterItem, chapterIndex) => {
                        return (
                            <div className={styles.pointWrapper} key={chapterIndex}>
                                <CustomLink to={`left-chapter-${chapterIndex}`} spy={true}
                                    activeClass={styles.pointContentActive}
                                    smooth={true} isDynamic={true} offset={-72}
                                    className={styles.pointContent}
                                    onClick={(event) => {
                                        if (chapterItem.subsection.length > 0) {
                                            slideDownUpHandler(event, chapterIndex)
                                        }
                                    }}
                                >
                                    <p className={styles.pointParagraph}>
                                        <span className={styles.pointText} dangerouslySetInnerHTML={{ __html: `${lang === "en" ? chapterItem.section_title_en : chapterItem.section_title}` }} />
                                        {chapterItem.subsection.length > 0
                                            ? <span className={styles.pointArrow} data-arrow={chapterIndex}>►</span>
                                            : null
                                        }
                                    </p>
                                </CustomLink>
                                {chapterItem.subsection.length > 0 ?
                                    <div className={styles.subPoints} data-subpoint={chapterIndex}>
                                        {chapterItem.subsection.map((subChapterItem, subChapterIndex) => {
                                            return (
                                                <CustomLink to={`left-chapter-${chapterIndex}.${subChapterIndex}`} spy={true}
                                                    activeClass={`${showContext.theme === 'light' ? styles.subPointActive : styles.subPointActiveDark}`}
                                                    smooth={true} isDynamic={true} offset={size.width <= 450 ? -72 : 0}
                                                    key={`${chapterIndex}.${subChapterIndex}`} className={styles.subPoint}
                                                >
                                                    {lang === "en" ? subChapterItem.subsection_title_en : subChapterItem.subsection_title}
                                                </CustomLink>
                                            )
                                        })}
                                    </div>
                                    : null}
                            </div>
                        )
                    })
                }
                {descriptionActiveLeft || descriptionActiveRight
                    ? size.width > 450 ?
                        <div className={styles.pointWrapper}>
                            <TractateLink type={"subChapter"} idToScroll={"desc"} style={styles.pointContent} desc={true}>
                                <p className={styles.pointParagraph}>
                                    <span className={styles.pointText}>{translation.articles.description}</span>
                                </p>
                            </TractateLink>
                        </div>
                        : <div className={styles.pointWrapper} style={{ display: `${descriptionActiveLeft || descriptionActiveRight ? "flex" : "none"}` }}>
                            <CustomLink to={`left-chapter-desc`} spy={true}
                                activeClass={styles.pointContentActive}
                                smooth={true} isDynamic={true} offset={size.width <= 450 ? -72 : 0}
                                className={styles.pointContent}
                            >
                                <p className={styles.pointParagraph}>
                                    <span className={styles.pointText}>{translation.articles.description}</span>
                                </p>
                            </CustomLink>
                        </div>
                    : null
                }
                {leftPhotoUrls.length > 1 || rightPhotoUrls.length > 1
                    ? size.width > 450 ?
                        <div className={styles.pointWrapper}>
                            <TractateLink type={"subChapter"} idToScroll={"descPhoto"} style={styles.pointContent} desc={true}>
                                <p className={styles.pointParagraph}>
                                    <span className={styles.pointText}>{translation.articles.descriptionPhoto}</span>
                                </p>
                            </TractateLink>
                        </div>
                        : <div className={styles.pointWrapper} style={{ display: `${leftPhotoUrls || rightPhotoUrls ? "flex" : "none"}` }}>
                            <CustomLink to={`left-chapter-descPhoto`} spy={true}
                                activeClass={styles.pointContentActive}
                                smooth={true} isDynamic={true} offset={size.width <= 450 ? -72 : 0}
                                className={styles.pointContent}
                            >
                                <p className={styles.pointParagraph}>
                                    <span className={styles.pointText}>{translation.articles.descriptionPhoto}</span>
                                </p>
                            </CustomLink>
                        </div>
                    : null
                }
            </>
        )
    }

    const contentSelect = (page, side) => {
        return (
            <div className={`${styles.contentWrapper} ${page === 'pdf' ? styles.pdfContainer : ''}`} id={`${side}Tractate`} data-tractatetype={page} data-zoom={"100"}>
                <div className={`${styles.content} ${page === 'pdf' ? styles.pdfContainer : ''}`}>
                    {page === 'pdf' ?
                        <iframe src={data.pdf2} title='Pdf' className={styles.pdfContainer}></iframe>
                        :
                        data.treatychapter.map((chapterItem, chapterIndex) => {
                            return (
                                <InView key={chapterIndex} rootMargin={`${size.width < 450 ? "0px 0px -72px 0px" : "0px"}`} onChange={(inView, entry) => size.width > 450 ? onScrollUpdate(entry, inView, side) : null} data-chapter={`${side}-chapter-${chapterIndex}`}>
                                    {({ inView, ref, entry, rootMargin }) => (
                                        <div className={styles.chapter} ref={ref} data-chapter={`${side}-chapter-${chapterIndex}`} id={`${side}-chapter-${chapterIndex}`}>
                                            {page === "originalSkan"
                                                ? chapterItem.images.map((itemImage, indexImage) => {
                                                    return (
                                                        <img key={indexImage} className={styles.pageImage} src={itemImage.filename} alt={`${translation.tractates.page}-${1}`} />
                                                    )
                                                })
                                                :
                                                <div dangerouslySetInnerHTML={{ __html: chapterItem[translateToEndpoint(page)] }} />
                                            }
                                            {chapterItem.subsection.length > 0
                                                ? chapterItem.subsection.map((subChapterItem, subChapterIndex) => {
                                                    return (
                                                        <InView key={subChapterIndex} onChange={(inView, entry) => size.width > 450 ? onScrollUpdate(entry, inView, side, "true") : null}>
                                                            {({ inView, ref, entry }) => (
                                                                <div className={styles.subChapter} ref={ref} data-chapter={`${side}-chapter-${chapterIndex}.${subChapterIndex}`} id={`${side}-chapter-${chapterIndex}.${subChapterIndex}`}>
                                                                    {page === "originalSkan"
                                                                        ? subChapterItem.images.map((itemImage, indexImage) => {
                                                                            return (
                                                                                <img key={indexImage} className={styles.pageImage} src={itemImage.filename} alt={`${translation.tractates.page}-${1}`} />
                                                                            )
                                                                        })
                                                                        : <div dangerouslySetInnerHTML={{ __html: subChapterItem[translateToEndpoint(page)] }} />
                                                                    }
                                                                </div>
                                                            )}
                                                        </InView>
                                                    )
                                                })
                                                : null
                                            }
                                        </div>
                                    )}
                                </InView>
                            )
                        })}
                    {page === 'pdf' ? null :
                        <InView onChange={(inView, entry) => size.width > 450 ? onScrollUpdate(entry, inView, side,) : null}>
                            {({ inView, ref, entry }) => (
                                <div className={styles.bibliography} ref={ref} id={`${side}-chapter-desc`} data-chapter={`${side}-chapter-desc`} style={{ display: `${(side === "left" ? descriptionActiveLeft : descriptionActiveRight) && page !== "originalSkan" ? "flex" : "flex"}` }}>
                                    {data
                                        ? descriptionSet(`${side}Tractate`, side)
                                        : null
                                    }
                                </div>
                            )}
                        </InView>}
                    {page === 'pdf' ? null :
                        <InView onChange={(inView, entry) => size.width > 450 ? onScrollUpdate(entry, inView, side,) : null}>
                            {({ inView, ref, entry }) => (
                                <div className={styles.bibliography} ref={ref} id={`${side}-chapter-descPhoto`} data-chapter={`${side}-chapter-descPhoto`} style={{ display: `${(side === "left" ? descriptionActiveLeft : descriptionActiveRight) && page !== "originalSkan" ? "flex" : "flex"}` }}>
                                    {data
                                        ? descriptionSetPhoto(`${side}Tractate`, side)
                                        : null
                                    }
                                </div>
                            )}
                        </InView>}

                </div>
            </div>
        )
    }

    const descriptionSet = (constainerID, side) => {
        const container = document.getElementById(constainerID)
        let sups = []
        if (container) {
            if (side === "left" && descriptionActiveLeft) {
                sups = container.querySelectorAll('[data-ancorWrapper]')
            } else if (side === "right" && descriptionActiveRight) {
                sups = container.querySelectorAll('[data-ancorWrapper]')
            }
        }

        let tableDesc = []
        let counter = 1
        sups.forEach((item, index) => {
            if (item.children.length > 0) {
                tableDesc.push({
                    number: counter,
                    description: item.children[1].innerHTML
                })
                counter++
                position_tooltip(item)
            }
        })

        return (
            tableDesc.map((item, index) => {
                return (
                    <p key={index} id={`${item.number}-description`}>
                        <a href={`#${item.number}-ancor`} data-ancor={`${item.number}-destination`} className={styles.bibliographyLink} data-destination={`${item.number}-ancor`} data-desc={true}>{item.number}.</a>
                        <span dangerouslySetInnerHTML={{ __html: item.description }} />
                    </p>
                )
            })
        )
    }

    const descriptionSetPhoto = (constainerID, side) => {
        const container = document.getElementById(constainerID)
        let sups = []
        if (container) {
            if (side === "left" && descriptionActiveLeft) {
                sups = leftPhotoUrls
            } else if (side === "right" && descriptionActiveRight) {
                sups = rightPhotoUrls
            }
        }

        let tableDesc = []
        let counter = 1
        sups.forEach((item, index) => {
            tableDesc.push({
                number: counter,
                url: item
            })
            counter++
        })

        return (
            tableDesc.map((item, index) => {
                return (
                    <p key={index} id={`${item.number}-description`}>
                        <a href={`#${item.number}-ancorPhoto`} data-ancor={`${item.number}-destinationPhoto`} className={styles.bibliographyLink} data-destination={`${item.number}-ancorPhoto`} data-desc={true}>{item.number}♫.</a>
                        <img className={styles.imgPhoto} src={item.url} alt={""} />
                    </p>
                )
            })
        )
    }

    function position_tooltip(item) {

        item.children[1].style.top = `${item.getBoundingClientRect().y - item.parentElement.getBoundingClientRect().y - item.children[1].getBoundingClientRect().height - 3}px`
        const left = item.getBoundingClientRect().x - item.parentElement.getBoundingClientRect().x - item.children[1].getBoundingClientRect().width / 2 + item.getBoundingClientRect().width / 2
        if (left < 0) {
            item.children[1].style.left = 0
        } else if (item.parentElement.getBoundingClientRect().width - left - item.children[1].getBoundingClientRect().width < 0) {
            item.children[1].style.left = item.parentElement.getBoundingClientRect().width - item.children[1].getBoundingClientRect().width + "px"
        } else {
            item.children[1].style.left = `${left}px`
        }
    }

    useEffect(() => {
        let tmpParams = []

        if (queryParams) {
            if (!Array.isArray(queryParams)) {
                tmpParams = [queryParams]
                setTypes([queryParams])
            } else {
                if (size.width > 750) {
                    tmpParams = queryParams.slice(0, 2)
                    setTypes(queryParams.slice(0, 2))
                } else {
                    tmpParams = queryParams.slice(0, 1)
                    setTypes(queryParams.slice(0, 1))
                }
            }
        }
        showContextRef.current.updateVersions(tmpParams)
    }, [queryParams, size])

    useEffect(() => {
        if (data) {
            const wrapper = document.getElementsByClassName(styles.tractateWrapper)[0]
            const body = document.getElementsByTagName('body')[0]
            if (size.width > 1920) {
                wrapper.style.maxWidth = "1665px"
            } else if (size.width <= 1920 && size.width >= 1600) {
                if (body.clientWidth !== window.innerWidth) {
                    wrapper.style.maxWidth = "calc(86.71vw - 7.5px)"
                } else {
                    wrapper.style.maxWidth = "86.71vw"
                }
            } else {
                wrapper.style.maxWidth = "unset"
            }
        }

    }, [data, size])

    useEffect(() => {
        if (data) {
            if (size.width > 1145) {
                const contents = document.getElementsByClassName(styles.contents)[0]
                let top = (size.height - contents.clientHeight) / 2;
                if (top < 50) {
                    contents.style.top = `${top}px`
                } else {
                    contents.style.top = "50px"
                }
            }
            if (size.width > 1600 || size.width < 450) {
                const contentsWrapper = document.getElementsByClassName(styles.contentsWrapper)[0];
                let button = document.getElementById("btn-spis-mobile")
                button.classList.add(styles.notActive);
                button.classList.remove(styles.active)
                contentsWrapper.classList.remove(styles.contentsWrapperActive)
            }
        }
    }, [data, size])

    useEffect(() => {
        const clip = new ClipboardJS(`.${styles.copy}`);

        clip.on('success', function (e) {
            e.clearSelection();
        });
    }, [data])

    useEffect(() => {
        window.scrollTo(0, 0);
        axios
            .post(`${showContext.mainUrl}treaty/search?id=${parseInt(id)}`)
            .then(res => {
                setData(res.data)
            })
    }, [id, showContext.mainUrl])

    useEffect(() => {
        if (data && (descriptionActiveLeft || descriptionActiveRight)) {
            const leftContainer = document.getElementById("leftTractate")
            const rightContainer = document.getElementById("rightTractate")

            if (leftContainer) {
                const supsLeft = leftContainer.querySelectorAll('[data-ancor]')

                Array.from(supsLeft).forEach((item) => {
                    item.addEventListener("click", (e) => {
                        e.preventDefault();

                        if (window.innerWidth <= 450) {
                            document.querySelectorAll(`[data-ancor~="${e.target.dataset.destination}"]`)[0].scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            });
                        } else {
                            let leftElement = leftContainer.querySelectorAll(`[data-ancor~="${e.target.dataset.destination}"]`)[0]

                            if (leftElement.dataset.desc === "true") {
                                let topPosLeft = leftElement.parentElement.offsetTop + 1;
                                scrollTo(leftContainer, topPosLeft, 600);
                            } else {
                                let topPosLeft = Math.abs(leftContainer.children[0].getBoundingClientRect().height) - Math.abs(leftElement.parentElement.getBoundingClientRect().top) - item.parentElement.parentElement.getBoundingClientRect().top - item.parentElement.parentElement.getBoundingClientRect().height + 1
                                scrollTo(leftContainer, topPosLeft, 600);
                            }
                        }
                    })
                })
            }

            if (rightContainer) {
                const supsRight = rightContainer.querySelectorAll('[data-ancor]')

                Array.from(supsRight).forEach((item) => {
                    item.addEventListener("click", (e) => {
                        e.preventDefault();

                        if (window.innerWidth <= 450) {
                            document.querySelectorAll(`[data-ancor~="${e.target.dataset.destination}"]`)[0].scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            });
                        } else {
                            let rightElement = rightContainer.querySelectorAll(`[data-ancor~="${e.target.dataset.destination}"]`)[0]

                            if (rightElement.dataset.desc === "true") {
                                let topPosRight = rightElement.parentElement.offsetTop + 1;
                                scrollTo(rightContainer, topPosRight, 600);
                            } else {
                                let topPosRight = Math.abs(rightContainer.children[0].getBoundingClientRect().height) - Math.abs(rightElement.parentElement.getBoundingClientRect().top) - item.parentElement.parentElement.getBoundingClientRect().top - item.parentElement.parentElement.getBoundingClientRect().height + 1
                                scrollTo(rightContainer, topPosRight, 600);
                            }
                        }
                    })
                })
            }
        }
    }, [data, descriptionActiveLeft, descriptionActiveRight])

    useEffect(() => {
        if (data) {
            const leftContainer = document.getElementById("leftTractate")
            const rightContainer = document.getElementById("rightTractate")

            let value = false
            if (leftContainer && !descriptionActiveLeft) {
                const supsLeft = leftContainer.querySelectorAll('[data-ancorWrapper]');
                let counterLeft = 1
                supsLeft.forEach((item, index) => {
                    if (item.children.length > 0) {
                        if (item.children[0].classList.contains("tooltip")) {
                            item.innerHTML = `<a id="${counterLeft}-ancor" href="#${counterLeft}-description" class="${styles.descLink}" data-ancor="${counterLeft}-ancor" data-destination="${counterLeft}-destination">${counterLeft}</a> <div>${item.innerHTML}</div>`
                        }
                        counterLeft++
                        item.classList.remove("tooltipWrapper")
                        item.classList.add(styles.tooltipWrapper)
                        item.children[1].classList.add(styles.tooltip)
                        value = true
                    } else {
                        item.remove()
                    }
                })
                const supsLeftPhoto = leftContainer.querySelectorAll('[data-ancorwrapperphoto]');
                let counterLeftPhoto = 1
                supsLeftPhoto.forEach((item, index) => {
                    if (item.getElementsByTagName('img').length === 1) {
                        const src = item.getElementsByTagName('img')[0].src
                        setLeftPhotoUrls((prevState) => {
                            return [...prevState, src]
                        })
                        item.innerHTML = `<a id="${counterLeftPhoto}-ancorPhoto" href="#${counterLeftPhoto}-descriptionPhoto" class="${styles.descLink}" data-ancor="${counterLeftPhoto}-ancorPhoto" data-destination="${counterLeftPhoto}-destinationPhoto">${counterLeftPhoto}♫</a>`
                        counterLeftPhoto++
                        item.classList.remove("tooltipWrapper")
                        item.classList.add(styles.tooltipWrapper)
                    } else {
                        item.remove()
                    }
                })
                if (value) {
                    setDescriptionActiveLeft(true)
                }
            }

            if (rightContainer && !descriptionActiveRight) {
                const supsRight = rightContainer.querySelectorAll('[data-ancorWrapper]');
                let counterRight = 1
                supsRight.forEach((item, index) => {
                    if (item.children.length > 0) {
                        if (item.children[0].classList.contains("tooltip")) {
                            item.innerHTML = `<a id="${counterRight}-ancor" href="#${counterRight}-description" class="${styles.descLink}" data-ancor="${counterRight}-ancor" data-destination="${counterRight}-destination">${counterRight}</a> <div>${item.innerHTML}</div>`
                        }
                        counterRight++
                        item.classList.remove("tooltipWrapper")
                        item.classList.add(styles.tooltipWrapper)
                        item.children[1].classList.add(styles.tooltip)
                        value = true
                    } else {
                        item.remove()
                    }
                })
                const supsRightPhoto = rightContainer.querySelectorAll('[data-ancorwrapperphoto]');
                let counterRightPhoto = 1
                supsRightPhoto.forEach((item, index) => {
                    if (item.getElementsByTagName('img').length === 1) {
                        const src = item.getElementsByTagName('img')[0].src
                        setRightPhotoUrls((prevState) => {
                            return [...prevState, src]
                        })
                        item.innerHTML = `<a id="${counterRightPhoto}-ancorPhoto" href="#${counterRightPhoto}-descriptionPhoto" class="${styles.descLink}" data-ancor="${counterRightPhoto}-ancorPhoto" data-destination="${counterRightPhoto}-destinationPhoto">${counterRightPhoto}♫</a>`
                        counterRightPhoto++
                        item.classList.remove("tooltipWrapper")
                        item.classList.add(styles.tooltipWrapper)
                    } else {
                        item.remove()
                    }
                })
                if (value) {
                    setDescriptionActiveRight(true)
                }
            }

        }
    }, [data, descriptionActiveLeft, descriptionActiveRight])

    useEffect(() => {
        if (size && prevSize) {
            if (size.width > 750 && prevSize.width <= 750) {
                setDescriptionActiveRight(false)
            }
        }
    }, [prevSize, size])

    return (
        <>
            <Loader data={data} />
            {data ?
                <div className={styles.wrapper}>
                    <Breadcrumbs lang={lang} title={`${translation.tractates.tractate} ${data.author[0].first_name} ${data.author[0].last_name} ${lang === "pl" ? data.title_ocr : data.title_ocr_en}`} />
                    <div className={styles.titleWrapper}>
                        <Link to={`/${lang}/tworcy/autorzy/${data.author[0].id}-${slug(`${data.author[0].first_name} ${data.author[0].last_name}`)}`}>
                            <div className={styles.author} dangerouslySetInnerHTML={{ __html: `${data.author[0].first_name} ${data.author[0].last_name}` }} />
                        </Link>
                        <div className={styles.title} dangerouslySetInnerHTML={{ __html: `${lang === "pl" ? data.title_ocr : data.title_ocr_en}` }} />
                    </div>
                    <TractateSelect presentation={true} data={data} />
                    {size.width <= 450 ?
                        <div className={styles.stickyWrapper}>
                            <div className={styles.phoneContentsWrapper}>
                                <div className={styles.contentsOpen} onClick={() => { openHandlerPhone(); slideDownUp(spisWrapper.current, styles.spisWrapperOpen) }}>
                                    <p className={styles.spis}>{translation.articles.spis}</p>
                                    <div className={`${styles.btn} ${styles.notActive}`} id={"btn-spis-phone"}>
                                        <span />
                                        <span />
                                        <span />
                                    </div>
                                </div>
                                <div className={styles.spisWrapper} ref={spisWrapper}>
                                    {spis()}
                                </div>
                            </div>
                        </div>
                        : null
                    }
                    <div className={styles.tractateWrapper}>
                        <div className={`${styles.tractate} ${styles.left} ${types[1] ? '' : styles.fullWidth}`}>
                            {types[0] === 'pdf' ? null :
                                <div className={styles.tractateTitleWrapper}>
                                    <span className={styles.tractateTitle}>{translation.tractates.version[types[0]]}</span>
                                </div>
                            }
                            {types[0] === 'pdf' ? null :
                                <div className={styles.tractateMenu}>
                                    <div className={styles.menuItem}>
                                        <div className={styles.menuImage} onClick={() => zoom("leftTractate")}
                                            style={{ backgroundImage: `url(${showContext.theme === 'light' ? zoom_black : zoom_white})` }}
                                        />
                                    </div>
                                    <div className={styles.menuItem}>
                                        <div className={styles.menuImage} onClick={() => unZoom("leftTractate")}
                                            style={{ backgroundImage: `url(${showContext.theme === 'light' ? unZoom_black : unZoom_white})` }}
                                        />
                                    </div>
                                    <div className={styles.menuItem}>
                                        <div className={styles.menuImage} onClick={() => setOpenedModal1(!openedModal1)}
                                            style={{ backgroundImage: `url(${showContext.theme === 'light' ? quote_black : quote_white})` }}
                                        />
                                    </div>
                                    <div className={styles.menuItem}>
                                        <div className={styles.menuImage} onClick={() => { window.open(`${types[0] === "polski" ? data.pdf_name_pl : types[0] === "angielski" ?  data.pdf_name_en : types[0] === "originalEdycja" ? data.pdf_name_ocr : data.pdf_name}`); }}
                                             style={{ backgroundImage: `url(${showContext.theme === 'light' ? download_black : download_white})` }}
                                        />
                                    </div>
                                </div>
                            }
                            {contentSelect(types[0], "left")}
                        </div>
                        {types[1]
                            ? <div className={`${styles.tractate} ${styles.right}`}>
                                {types[1] === 'pdf' ? null :
                                    <div className={styles.tractateTitleWrapper}>
                                        <span className={styles.tractateTitle}>{translation.tractates.version[types[1]]}</span>
                                    </div>
                                }
                                {types[1] === 'pdf' ? null :
                                    <div className={styles.tractateMenu}>
                                        <div className={styles.menuItem}>
                                            <div className={styles.menuImage} onClick={() => zoom("rightTractate")}
                                                style={{ backgroundImage: `url(${showContext.theme === 'light' ? zoom_black : zoom_white})` }}
                                            />
                                        </div>
                                        <div className={styles.menuItem}>
                                            <div className={styles.menuImage} onClick={() => unZoom("rightTractate")}
                                                style={{ backgroundImage: `url(${showContext.theme === 'light' ? unZoom_black : unZoom_white})` }}
                                            />
                                        </div>
                                        <div className={styles.menuItem}>
                                            <div className={styles.menuImage} onClick={() => setOpenedModal2(!openedModal2)}
                                                style={{ backgroundImage: `url(${showContext.theme === 'light' ? quote_black : quote_white})` }}
                                            />
                                        </div>
                                        <div className={styles.menuItem}>
                                            <div className={styles.menuImage} onClick={() => { window.open(`${types[1] === "polski" ? data.pdf_name_pl : types[1] === "angielski" ?  data.pdf_name_en : types[1] === "originalEdycja" ? data.pdf_name_ocr : data.pdf_name}`); }}
                                                 style={{ backgroundImage: `url(${showContext.theme === 'light' ? download_black : download_white})` }}
                                            />
                                        </div>
                                    </div>
                                }
                                {contentSelect(types[1], "right")}
                            </div>
                            : <div className={`${styles.tractate} ${styles.right} ${styles.empty}`}>
                                <div className={styles.emptyTractateWrapper}>
                                    {translation.tractates.emptyTractate}
                                </div>
                            </div>
                        }
                        <div className={styles.mobileContents}>
                            <div className={styles.contentsWrapper}>
                                <div className={styles.contentsOpen} onClick={() => { openHandlerMobile() }}>
                                    <div className={`${styles.btn} ${styles.notActive}`} id={"btn-spis-mobile"}>
                                        <span />
                                        <span />
                                        <span />
                                    </div>
                                </div>
                                <div className={styles.contents}>
                                    {size.width > 450 ? spis() : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal setOpened={setOpenedModal1} opened={openedModal1}>
                        {createQuotes(types[0], "left")}
                    </Modal>
                    {types[1] ?
                        <Modal setOpened={setOpenedModal2} opened={openedModal2}>
                            {createQuotes(types[1], "right")}
                        </Modal>
                        : null
                    }
                    <Footer />
                </div>
                : null}
        </>
    )
}

export default TractateShow