import React, { useLayoutEffect } from 'react';
import { useLocation } from "react-router-dom";

import Menu from '../../Components/Menu/menu'
import Bars from "../../Components/Bars/bars";
import Search from "../../Components/Search/search";
import useWindowSize from "../../Components/Helpers/hooks/useWindowSize";

import styles from './header.module.scss';
import translation from "../../Components/Helpers/translation";

function Header(props) {

	const { pathname } = useLocation();

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const size = useWindowSize();

	return (
		<div className={styles.headerWrapper}>
			<div className={styles.headerSubWrapper}>
				<div className={styles.subWrapper}>
					<Menu size={size}/>
					<span className={styles.title}>{translation.header.top}</span>
					<span className={styles.subtitle}>{translation.header.bottom}</span>
					{ (window.location.href.split('/')[4] === undefined || window.location.href.split('/')[4] === '') && size.width > 800 ? <Search/> : null}
				</div>
			<Bars/>
			{ (window.location.href.split('/')[4] === undefined || window.location.href.split('/')[4] === '') && size.width <= 800 ? <Search/> : null}
			</div>
		</div>
	);
}

export default Header;


