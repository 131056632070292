import React from 'react'
import { Link } from "react-router-dom";

function StandardLink (props) {

    return (
        <Link
            to={props.to}
            className={props.className}
            dangerouslySetInnerHTML={{__html: props.dangerouslySetInnerHTML}}
        />
    )
}

export default StandardLink;