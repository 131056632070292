import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams } from "react-router-dom";
import useWindowSize from "../../Components/Helpers/hooks/useWindowSize";

import Breadcrumbs from "../../Components/Breadcrumbs/breadcrumbs";
import translation from "../../Components/Helpers/translation";

import creators_1 from '../../assets/images/creators/creators_1_black.png'
import creators_2 from '../../assets/images/creators/creators_2_black.png'
import creators_3 from '../../assets/images/creators/creators_3_black.png'
import creators_4 from '../../assets/images/creators/creators_4_black.png'
import nifc_black_pl from '../../assets/images/creators/nifc_black_pl.png'
import nifc_black_en from '../../assets/images/creators/nifc_black_en.png'
import nifc_color_pl from '../../assets/images/creators/nifc_color_pl.png'
import nifc_color_en from '../../assets/images/creators/nifc_color_en.png'
import uni_black from '../../assets/images/creators/uni_black.png'

import styles from './creatorsList.module.scss'
import {ThemeContext} from "../../Components/Context/context";
import axios from "axios";
import {Loader} from "../../Components/Loader/loader";
import Footer from "../Footer/footer";

function CreatorsList(props) {

    const [data, setData] = useState(null)
    const [nifc, setNifc] = useState()
    const { lang, category } = useParams();
    const size = useWindowSize();
    const showContext = useContext(ThemeContext)
    let slug = require('slug')

    useEffect(() => {
        if(showContext.theme === "light") {
            if(lang === "pl") {
                setNifc(nifc_color_pl)
            } else {
                setNifc(nifc_color_en)
            }
        } else {
            if(lang === "pl") {
                setNifc(nifc_black_pl)
            } else {
                setNifc(nifc_black_en)
            }
        }
    },[showContext.theme, lang])

    const photo = () => {
        if(category[0] === "1") {
            return <img src={nifc} alt={''}/>
        } else if(category[0] === "2") {
            return <img className={styles.uni} src={uni_black} alt={''}/>
        } else {
            if(category === "autorzy"){
                return  <img className={styles.creator_1} src={creators_1} alt={''}/>
            } else if(category === "tlumacze") {
                return  <img className={styles.creator_2} src={creators_2} alt={''}/>
            } else if(category === "redaktorzy") {
                return <img className={styles.creator_3} src={creators_3} alt={''}/>
            } else if(category === "inni") {
                return  <img className={styles.creator_4} src={creators_4} alt={''}/>
            } else {
                return null
            }
        }
    }

    const institutions = () => {
        return (
            <div className={styles.institutionWrapper}>
                <span className={styles.title}>{translation.creators.title.main}</span>
                <div className={styles.topWrapper}>
                    {size.width <= 1350
                        ? <div className={styles.polygon}>
                            <div className={styles.imageWrapper}>
                                {photo()}
                            </div>
                        </div>
                        : null
                    }
                    <div className={styles.right}>
                        <span className={styles.name}>{data.title}</span>
                        <a className={styles.link} href={`https://${data.link}`} target={"_blank"} rel="noreferrer">
                            <div className={styles.arrow}/>
                            {data.link}
                        </a>
                    </div>
                </div>
                <img className={styles.image} src={data.image} alt={''}/>
                <div className={styles.text} dangerouslySetInnerHTML={{__html : data.text}}/>
                <Link to={`/${lang}/tworcy`} className={styles.back}>
                    <div className={styles.arrow}/>
                    {translation.creators.back.static}
                </Link>
            </div>
        )
    }

    const list = () => {
        return (
            <>
                <div className={styles.list}>
                    {size.width <= 1350
                        ? <div className={styles.polygon}>
                            <div className={`${styles.imageWrapper} ${styles.noMargin}`}>
                                {photo()}
                            </div>
                        </div>
                        : null
                    }
                    <span className={`${styles.title} ${styles.center} ${styles.noMarginTitle}`}>{translation.creators.title[category]}</span>
                    {data.map((item, index) => {
                        return (
                            <div key={index} className={styles.container}>
                                <div className={styles.image} style={{backgroundImage: `url(${item.image})`}}/>
                                <div className={styles.right}>
                                    <span className={styles.name} dangerouslySetInnerHTML={{__html: `${item.first_name} ${item.last_name}`}}/>
                                    <span className={styles.uni} dangerouslySetInnerHTML={{__html: item.membership}}/>
                                    <span className={styles.mail} dangerouslySetInnerHTML={{__html: item.email}}/>
                                    <span className={styles.redact}>{translation.creators.description[category]}</span>
                                    {item.works_relation ?
                                        <div className={styles.tractateWrapper}>
                                            {item.works_relation.map((subItem,subIndex) => {
                                                return (
                                                    <Link to={`/${lang}/${subItem.type_of_work === 1 ? 'artykuly-wprowadzajace' : 'traktaty' }/${subItem.works}-${slug(subItem.title)}`} key={subIndex} className={styles.tractate}>
                                                        <span dangerouslySetInnerHTML={{__html: subItem.short_desc_work}}/>
                                                    </Link>
                                                )
                                            })}
                                        </div>
                                        : <span className={styles.tractate} dangerouslySetInnerHTML={{__html: item.short_desc_treaty}}/>
                                    }
                                    <Link className={styles.more} to={`/${lang}/tworcy/${category}/${item.id}-${slug(`${item.first_name} ${item.last_name}`)}`}>{translation.creators.more}</Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <Link to={`/${lang}/tworcy`} className={styles.back}>
                    <div className={styles.arrow}/>
                    {translation.creators.back.static}
                </Link>
            </>

        )
    }

    useEffect(() => {
        const selectLink = () => {
            let link = ''
            if(category[0] === "1") {
                link = `text-pages/search?id=1&lang=${lang}`
            } else if(category[0] === "2") {
                link = `text-pages/search?id=2&lang=${lang}`
            } else {
                if(category === "autorzy"){
                    link = `author?lang=${lang}`
                } else if(category === "tlumacze") {
                    link = `translator?lang=${lang}`
                } else if(category === "redaktorzy") {
                    link = `editor?lang=${lang}`
                } else if(category === "inni") {
                    link = `other?lang=${lang}`
                }
            }
            return link
        }

        axios
            .post(`${showContext.mainUrl}${selectLink()}` )
            .then(res => {
                setData(res.data)
            })
    },[category, lang, showContext.mainUrl])

    return (
        <>
            <Loader data={data}/>
            {data ?
                <div className={styles.wrapper}>
                    <Breadcrumbs lang={lang}/>
                    <div className={styles.subWrapper}>
                        { size.width > 1350
                            ? <div className={`${styles.polygon} ${ category[0] === '1' || category[0] === '2' ? styles.polygonList : ''}`}>
                                <div className={styles.imageWrapper}>
                                    {photo()}
                                </div>
                            </div>
                            : null
                        }
                        {category[0] === '1' || category[0] === '2'
                            ? institutions()
                            : list()
                        }
                    </div>
                    <Footer/>
                </div>
                : null
            }
        </>
    )
}

export default CreatorsList;