import React from 'react';

import aboutSearch from '../../assets/images/header/bars/aboutSearch_bar.jpg'
import esejBar from '../../assets/images/header/bars/eseje_bar.jpg'
import mainPageBar from '../../assets/images/header/bars/mainPage_bar.jpg'
import multimediaBar from '../../assets/images/header/bars/multimedia_bar.jpg'
/*import traktatCherubiniBar from '../../assets/images/header/bars/traktat_cherubini_bar.jpg'
import traktatForkelBar from '../../assets/images/header/bars/traktat_forkel_bar.jpg'
import traktatKochBar from '../../assets/images/header/bars/traktat_koch_bar.jpg'
import traktatMarpurgBar from '../../assets/images/header/bars/traktat_marpurg_bar.jpg'
import traktatReichaBar from '../../assets/images/header/bars/traktat_reicha_bar.jpg'*/
import tworcyBar from '../../assets/images/header/bars/tworcy_bar.jpg'

import styles from './bars.module.scss'

function Bars() {

    const url = window.location.href.split('/')

    const switchBars = (url) => {

        switch (url[4]) {
            case undefined :
                return mainPageBar
            case 'wyszukanie' :
                return aboutSearch
            case 'o-projekcie' :
                return aboutSearch
            case 'artykuly-wprowadzajace' :
                return esejBar
            case 'wyszukiwanie' :
                return aboutSearch
            case 'multimedia' :
                return multimediaBar
            case 'traktaty' :
                if(url[5] === undefined) {
                    return aboutSearch
                } else {
                    switch (url[5]) {
                        case '' :
                            return aboutSearch
                        default :
                            return aboutSearch
                    }
                }
            case 'tworcy' :
                return tworcyBar
            default:
                return mainPageBar
        }
    }


    return (
        <img className={styles.image} src={switchBars(url)} alt={''}/>
    )
}

export default Bars;