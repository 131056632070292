import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import Breadcrumbs from "../../Components/Breadcrumbs/breadcrumbs";
import axios from "axios";
import { ThemeContext } from "../../Components/Context/context";
import clamp from "clamp-js";

import TractateSelect from "../../Components/TractateSelect/tractateSelect";
import useWindowSize from "../../Components/Helpers/hooks/useWindowSize";
import { Loader } from "../../Components/Loader/loader";

import translation from "../../Components/Helpers/translation";

import styles from './tractate.module.scss'
import Footer from "../Footer/footer";

function Tractate(props) {

    const { lang, id } = useParams();
    const [data, setData] = useState(null);
    const [imageHeight, setImageHeight] = useState(0)
    const showContext = useContext(ThemeContext);
    const image = useRef()
    const size = useWindowSize()
    let slug = require('slug')

    useEffect(() => {
        const elements = document.getElementsByClassName(styles.entry)
        Array.from(elements).forEach((element) => {
            clamp(element, {clamp: 8})
        })
    }, [data])

    const getImageHeight = () => {
        setImageHeight(image.current.offsetHeight)
    }

    useEffect(() => {
        const image = document.getElementById("image")
        if (image) {
            image.addEventListener('load', getImageHeight);
            return () => image.removeEventListener('load', getImageHeight);
        }
    }, [data])

    useEffect(() => {
        if (imageHeight > 0) {
            image.current.style.top = (size.height - image.current.offsetHeight) / 2 + "px"
        }
    }, [imageHeight, size])

    useEffect(() => {
        axios
            .post(`${showContext.mainUrl}treaty/search?id=${parseInt(id)}`)
            .then(res => {
                setData(res.data)
            })
    }, [id, showContext.mainUrl])

    return (
        <>
            <Loader data={data}/>
            {data ?
                <div className={styles.wrapper}>
                    <Breadcrumbs lang={lang} title={`${translation.tractates.tractate} ${data.author[0].first_name} ${data.author[0].last_name} ${lang === "pl" ? data.title_ocr : data.title_ocr_en}`}/>
                    <div className={styles.subWrapper}>
                        <div className={styles.left}>
                            <div className={styles.link}>
                                <Link to={`/${lang}/tworcy/autorzy/${data.author[0].id}-${slug(`${data.author[0].first_name} ${data.author[0].last_name}`)}`}>
                                    <div className={styles.author} dangerouslySetInnerHTML={{__html: `${data.author[0].first_name} ${data.author[0].last_name}`}}/>
                                </Link>
                            </div>
                            <div className={styles.title} dangerouslySetInnerHTML={{__html: `${lang === "pl" ? data.title_ocr : data.title_ocr_en}`}}/>
                            <img className={styles.image} src={data.miniature} alt={lang === "pl" ? data.miniature_alt_pl : data.miniature_alt_en} id={"image"} ref={image}/>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.link}>
                                <Link to={`/${lang}/tworcy/autorzy/${data.author[0].id}-${slug(`${data.author[0].first_name} ${data.author[0].last_name}`)}`}>
                                    <div className={styles.author}
                                         dangerouslySetInnerHTML={{__html: `${data.author[0].first_name} ${data.author[0].last_name}`}}/>
                                </Link>
                            </div>
                            <div className={styles.title} dangerouslySetInnerHTML={{__html: `${lang === "pl" ? data.title_ocr : data.title_ocr_en}`}}/>
                            <TractateSelect data={data}/>
                            <div className={styles.firstEditionWrapper}>
                                <span className={styles.firstEditionTitle}>{translation.tractates.firstEdition}</span>
                                <div className={styles.firstEdition}
                                     dangerouslySetInnerHTML={{__html: data.first_published_year}}/>
                            </div>
                            <div className={styles.translationWrapper}>
                                <span className={styles.translationTitle}>{translation.tractates.translationPl}</span>
                                <div className={styles.translations}>
                                    <div className={styles.translation}>
                                        {data.translatorspl.map((item, index) => {
                                            return (
                                                <>
                                                    <span className={styles.name}>{translation.tractates.translator}</span>
                                                    <Link to={`/${lang}/tworcy/tlumacze/${item.id}-${slug(`${item.first_name} ${item.last_name}`)}`}>
                                                        <div dangerouslySetInnerHTML={{__html: `${lang === 'pl' ? `${item.first_name} ${item.last_name}` : `${item.first_name_en} ${item.last_name_en}` }`}}/>
                                                    </Link>
                                                    ,
                                                </>
                                            )
                                        })}
                                    </div>
                                    <div className={styles.translation}>
                                        {data.editorspl.map((item, index) => {
                                            return (
                                                <>
                                                    <span className={styles.name}>{translation.tractates.editor}</span>
                                                    <Link to={`/${lang}/tworcy/redaktorzy/${item.id}-${slug(`${item.first_name} ${item.last_name}`)}`}>
                                                        <div dangerouslySetInnerHTML={{__html: `${lang === 'pl' ? `${item.first_name} ${item.last_name}` : `${item.first_name_en} ${item.last_name_en}` }`}}/>
                                                    </Link>
                                                    {index !== data.editorspl.length - 1 ? "," : null}
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.translationWrapper}>
                                <span className={styles.translationTitle}>{translation.tractates.translationEn}</span>
                                <div className={styles.translations}>
                                    <div className={styles.translations}>
                                        <div className={styles.translation}>
                                            {data.translatorsen.map((item, index) => {
                                                return (
                                                    <>
                                                        <span className={styles.name}>{translation.tractates.translator}</span>
                                                        <Link to={`/${lang}/tworcy/tlumacze/${item.id}-${slug(`${item.first_name} ${item.last_name}`)}`}>
                                                            <div dangerouslySetInnerHTML={{__html: `${lang === 'pl' ? `${item.first_name} ${item.last_name}` : `${item.first_name_en} ${item.last_name_en}` }`}}/>
                                                        </Link>
                                                        ,
                                                    </>
                                                )
                                            })}
                                        </div>
                                        <div className={styles.translation}>
                                            {data.editorsen.map((item, index) => {
                                                return (
                                                    <>
                                                        <span className={styles.name}>{translation.tractates.editor}</span>
                                                        <Link to={`/${lang}/tworcy/redaktorzy/${item.id}-${slug(`${item.first_name} ${item.last_name}`)}`}>
                                                            <div dangerouslySetInnerHTML={{__html: `${lang === 'pl' ? `${item.first_name} ${item.last_name}` : `${item.first_name_en} ${item.last_name_en}` }`}}/>
                                                        </Link>
                                                        {index !== data.editorspl.length - 1 ? "," : null}
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {data.article.published !== 0 ?
                                <>
                                    <span className={styles.entryTitle}>{translation.tractates.entry}</span>
                                    <div className={styles.entry} dangerouslySetInnerHTML={{__html: lang === "pl" ? data.chapter.text : data.chapter.text_en }}/>
                                    <div className={styles.entryReadAll}>
                                        <Link to={`/${lang}/artykuly-wprowadzajace/${data.article.id}-${slug(data.article.title)}`}>
                                            {translation.tractates.readAll}
                                        </Link>
                                    </div>
                                </>
                                : null
                            }

                        </div>
                    </div>
                    <Footer/>
                </div>
                : null
            }
        </>
    )
}

export default Tractate;