function slideDownUp(container, styleOpen) {
    /** Slide down. */

    if (!container.classList.contains(styleOpen)) {
        container.classList.add(styleOpen)
        container.style.height = "auto"

        let height = container.clientHeight + "px"

        container.style.height = "0px"

        setTimeout(() => {
            container.style.height = height
        }, 1)

        setTimeout(() => {
            container.style.height = "auto"
            container.style.overflowY = "auto"
        }, 402)
        /** Slide up. */
    } else {
        container.style.overflowY = "hidden"
        container.style.height = container.clientHeight + "px"
        setTimeout(() => {
            container.style.height = "0px"
        },1)


        setTimeout(() => {
            container.classList.remove(styleOpen)
        }, 402)
    }
}

export default slideDownUp;