import React from 'react';
import ReactDOM from 'react-dom';
import ThemeContextProvider from "./Components/Context/context";
/*import TagManager from 'react-gtm-module';*/

import Builder from './Containers/Builder/Builder';

/*const tagManagerArgs = {
  gtmId: 'GTM-MBP8HKC'
}

TagManager.initialize(tagManagerArgs)*/

ReactDOM.render(
    <ThemeContextProvider>
        <Builder />
    </ThemeContextProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
