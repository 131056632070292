import React, { useContext, useEffect } from "react";
import { ThemeContext } from "../Context/context";

import styles from "./tractateSelect.module.scss"
import translation from "../Helpers/translation";
import useWindowSize from "../Helpers/hooks/useWindowSize";
import { useParams, useHistory } from "react-router-dom";
import queryString from 'query-string';

function TractateSelect(props) {

    const size = useWindowSize()
    const showContext = useContext(ThemeContext)
    const history = useHistory();
    const { lang, id } = useParams();

    const selectVersion = (event) => {
        const version = event.target.dataset.version
        let tmpVersions = [...showContext.versions];

        if (!tmpVersions.includes(version)) {
            if (size.width > 750) {
                if (tmpVersions.length < 2) {
                    tmpVersions.push(version)
                } else {
                    tmpVersions.shift()
                    tmpVersions.push(version)
                }
            } else {
                if (tmpVersions.length < 1) {
                    tmpVersions.push(version)
                } else {
                    tmpVersions.shift()
                    tmpVersions.push(version)
                }
            }
        } else {
            tmpVersions = tmpVersions.filter(function (value, index, arr) {
                return value !== version;
            });
        }
        showContext.updateVersions(tmpVersions)
    }

    const createParams = () => {
        return queryString.stringify({ types: showContext.versions }, { arrayFormat: 'bracket' })
    }

    const clearVersions = () => {
        showContext.updateVersions([])
    }
    const showVersions = () => {
        if (showContext.versions.length >= 1) {
            history.push(`/${lang}/traktaty/${id}/widok-values?${createParams()}`)
            if (props.presentation) {
                window.location.reload(false);
            }
        }
    }

    useEffect(() => {
        const versionsDiv = document.getElementsByClassName(styles.version)

        Array.from(versionsDiv).forEach((version) => {
            if (showContext.versions.includes(version.dataset.version)) {
                version.classList.add(styles.versionActive)
            } else {
                version.classList.remove(styles.versionActive)
            }
        })
    }, [showContext.versions])

    return (
        <div className={`${styles.wrapper} ${props.presentation ? styles.presWrapper : ''}`}>
            <div className={styles.top}>
                <div className={styles.chooseWrapper}>
                    <span>
                        {size.width > 620
                            ? !props.presentation
                                ? `${translation.tractates.chooseDesktop_1} ${translation.tractates.chooseDesktop_2}`
                                : `${translation.tractates.chooseDesktop_2}`
                            : !props.presentation
                                ? `${translation.tractates.chooseMobile_1}`
                                : `${translation.tractates.chooseMobile_2}`
                        }
                    </span>
                    <span>
                        {!props.presentation
                            ? size.width > 620
                                ? translation.tractates.chooseDesktop_3
                                : translation.tractates.chooseMobile_2
                            : size.width > 750
                                ? translation.tractates.chooseDesktop_3
                                : null
                        }
                    </span>
                </div>
                <div className={styles.versionWrapper}>
                    <div className={styles.version} data-version={'originalSkan'} onClick={event => selectVersion(event)}>
                        {translation.tractates.version.originalSkan}
                    </div>
                    <div className={styles.version} data-version={'originalEdycja'} onClick={event => selectVersion(event)} >
                        {translation.tractates.version.originalEdycja}
                    </div>
                    <div className={`${styles.version} ${props.presentation ? styles.versionMargin : ''}`} data-version={'polski'} onClick={event => selectVersion(event)}>
                        {translation.tractates.version.polski}
                    </div>
                    <div className={`${styles.version} ${props.presentation ? styles.versionMargin : ''}`} data-version={'angielski'} onClick={event => selectVersion(event)}>
                        {translation.tractates.version.angielski}
                    </div>
                </div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.clear} onClick={clearVersions}>
                    {translation.tractates.clear}
                </div>
                <div className={styles.show} onClick={showVersions}>
                    {translation.tractates.show}
                </div>
            </div>
        </div>
    )
}

export default TractateSelect