import React, { useEffect, useContext } from 'react';
import {Link, useParams} from "react-router-dom";
import {ThemeContext} from "../Context/context";

import translation from "../Helpers/translation";

import styles from './menu.module.scss';


function Menu(props) {

    const showContext = useContext(ThemeContext)
    const { lang } = useParams();

    const showHideMenu = () => {
        window.scrollTo(0,0)
        let button = document.getElementById("btn")
        let menu = document.getElementById("menuMobile")
        if(button.classList.contains(styles.active)) {
            button.classList.add(styles.notActive);
            button.classList.remove(styles.active)
            menu.classList.remove(styles.show)
            if(props.size.width <= 520) {
                document.getElementsByTagName('body')[0].style.overflowY = "auto"
            }
        } else {
            button.classList.add(styles.active);
            button.classList.remove(styles.notActive)
            menu.classList.add(styles.show)
            if(props.size.width <= 520) {
                document.getElementsByTagName('body')[0].style.overflowY = "hidden"
            }
        }
    }

    useEffect(() => {
        let button = document.getElementById("btn")
        if(button.classList.contains(styles.active)) {
            if(props.size.width > 520) {
                document.getElementsByTagName('body')[0].style.overflowY = "auto"
            } else {
                document.getElementsByTagName('body')[0].style.overflowY = "hidden"
            }
        }
    },[props.size])

    const updateLang = () => {
        const url = window.location.href.split('/')
        let newUrl = ""

        for( let i = 0; i < url.length; i++) {
            if (i === 3) {
                if( url[4] === undefined) {
                    if(url[i] === "pl") {
                        newUrl = newUrl + "en"
                    } else {
                        newUrl = newUrl + "pl"
                    }
                } else {
                    if(url[i] === "pl") {
                        newUrl = newUrl + "en/"
                    } else {
                        newUrl = newUrl + "pl/"
                    }
                }
            } else if (i === url.length - 1){
                newUrl = newUrl + url[i]
            } else {
                newUrl = newUrl + url[i] + "/"
            }
        }
        window.location.href = newUrl;
    }

    return (
        <div className={styles.menuWrapper}>
            <div className={styles.menu}>
                <Link to={`/${lang}`}>
                    {translation.menu.mainPage}
                </Link>
                <Link to={`/${lang}/o-projekcie`}>
                    {translation.menu.about}
                </Link>
                <Link to={`/${lang}/artykuly-wprowadzajace`}>
                    {translation.menu.articles}
                </Link>
                <Link to={`/${lang}/traktaty`}>
                    {translation.menu.tractates}
                </Link>
                <Link to={`/${lang}/tworcy`}>
                    {translation.menu.creators}
                </Link>
                <Link to={`/${lang}/multimedia`}>
                    {translation.menu.multimedia}
                </Link>
            </div>
            <div className={styles.mobileMenuWrapper}>
                <div className={`${styles.btn} ${styles.notActive}`} id={"btn"} onClick={showHideMenu}>
                    <span/>
                    <span/>
                    <span/>
                </div>
                <div className={styles.menuMobile} id={"menuMobile"}>
                    <Link to={`/${lang}`} onClick={()=>{showHideMenu()}}>
                        {translation.menu.mainPage}
                    </Link>
                    <Link to={`/${lang}/o-projekcie`} onClick={()=>{showHideMenu()}}>
                        {translation.menu.about}
                    </Link>
                    <Link to={`/${lang}/artykuly-wprowadzajace`} onClick={()=>{showHideMenu()}}>
                        {translation.menu.articles}
                    </Link>
                    <Link to={`/${lang}/traktaty`} onClick={()=>{showHideMenu()}}>
                        {translation.menu.tractates}
                    </Link>
                    <Link to={`/${lang}/tworcy`} onClick={()=>{showHideMenu()}}>
                        {translation.menu.creators}
                    </Link>
                    <Link to={`/${lang}/multimedia`} onClick={()=>{showHideMenu()}}>
                        {translation.menu.multimedia}
                    </Link>
                </div>
            </div>
            <div className={styles.lang} >
                <div className={`${styles.box} ${lang === 'pl' ? styles.boxActive : ''}`} onClick={updateLang}>PL</div>
                <div className={`${styles.box} ${lang === 'en' ? styles.boxActive : ''}`} onClick={updateLang}>EN</div>
            </div>
            <div className={styles.theme} onClick={showContext.updateTheme}>
                <div className={`${styles.halfCircle} ${props.theme === 'dark' ? styles.halfCircleDark : ''}`}/>
            </div>

        </div>
    );
}

export default Menu;