import React, { useEffect, useState, useContext } from 'react';
import {ThemeContext} from "../../Components/Context/context";

import translation from "../../Components/Helpers/translation";
import orphans from "../../Components/Helpers/orphans";

import Footer1_white from '../../assets/images/footer/white/Footer1_white.png'
import Footer2_white from '../../assets/images/footer/white/Footer2_white.png'
import Footer2_white_en from '../../assets/images/footer/white/Footer2_white_en.png'
import Footer3_white from '../../assets/images/footer/white/Footer3_white.png'
import Footer4_white from '../../assets/images/footer/white/Footer4_white.png'
import Footer4_white_en from '../../assets/images/footer/white/Footer4_white_en.png'
import Footer5_white from '../../assets/images/footer/white/nifc_white.png'
import Footer6_white from '../../assets/images/footer/white/fb_white.png'
import Footer7_white from '../../assets/images/footer/white/insta_white.png'
import Footer1_black from '../../assets/images/footer/black/Footer1_black.png'
import Footer2_black from '../../assets/images/footer/black/Footer2_black.png'
import Footer2_black_en from '../../assets/images/footer/black/Footer2_black_en.png'
import Footer3_black from '../../assets/images/footer/black/Footer3_black.png'
import Footer4_black from '../../assets/images/footer/black/Footer4_black.png'
import Footer4_black_en from '../../assets/images/footer/black/Footer4_black_en.png'
import Footer5_black from '../../assets/images/footer/black/nifc_black.png'
import Footer6_black from '../../assets/images/footer/black/fb_black.png'
import Footer7_black from '../../assets/images/footer/black/insta_black.png'

import styles from	'./footer.module.scss'
import {useParams , useLocation} from "react-router-dom";
import ScrollToTop from "../../Components/Helpers/ScrollToTop/scrollToTop";


function Footer(props) {

	const location = useLocation()
	const [imageArray, setImageArray] = useState([Footer1_white,Footer2_white,Footer3_white,Footer4_white,Footer5_white,Footer6_white,Footer7_white])
	const showContext = useContext(ThemeContext)
	const { lang } = useParams();

	useEffect(() => {
		if(showContext.theme === 'light') {
			if(lang === 'pl') {
				setImageArray([Footer1_white,Footer2_white,Footer3_white,Footer4_white,Footer5_white,Footer6_white,Footer7_white])
			} else {
				setImageArray([Footer1_white,Footer2_white_en,Footer3_white,Footer4_white_en,Footer5_white,Footer6_white,Footer7_white])
			}
		} else {
			if(lang === 'pl') {
				setImageArray([Footer1_black, Footer2_black, Footer3_black, Footer4_black, Footer5_black, Footer6_black, Footer7_black])
			} else {
				setImageArray([Footer1_black, Footer2_black_en, Footer3_black, Footer4_black_en, Footer5_black, Footer6_black, Footer7_black])
			}
		}
	},[showContext.theme ,lang])

	useEffect(() => {
		if (location.pathname !== "/pl/wyszukiwanie/values") {
			orphans();
		}
	},[location.pathname])

	return (
		<div className={styles.footerWrapper}>
			<ScrollToTop showContext={showContext}/>
			<div className={styles.footerSubWrapper}>
				<div className={styles.titleWrapper}>
					<span className={styles.title}>{translation.footer.top}</span>
				</div>
				<div className={styles.footerSubSubWrapper}>
					<div className={styles.footer}>
						<div className={styles.leftWrapper}>
							<div className={styles.leftTop}>
								<div className={styles.item}>
									<span className={styles.description}>{translation.footer.left.description1}</span>
									<img src={imageArray[0]} alt={translation.footer.left.image.bergenCenter}/>
								</div>
								<div className={styles.item}>
									<span className={styles.description}>{translation.footer.left.description2}</span>
									<img src={imageArray[1]} alt={translation.footer.left.image.nifc}/>
								</div>
								<div className={styles.item}>
									<span className={styles.description}>{translation.footer.left.description3}</span>
									<img src={imageArray[2]} alt={translation.footer.left.image.bergenUni}/>
								</div>
								<div className={styles.item}>
									<span className={styles.description}>{translation.footer.left.description4}</span>
									<img className={styles.smaller} src={imageArray[3]} alt={translation.footer.left.image.mkidn}/>
								</div>
							</div>
							<div className={styles.leftBottom}>
								<span className={styles.descriptionBottom} dangerouslySetInnerHTML={{__html: translation.footer.left.bottom}}/>
							</div>
						</div>
						<div className={styles.separator}/>
						<div className={styles.rightWrapper}>
							<div className={styles.rightTop}>
								<a href={lang === "pl" ? "https://nifc.pl/pl" : "https://nifc.pl/en"} target={"_blank"} rel={"noreferrer"}>
									<img src={imageArray[4]} alt={translation.footer.right.image.nifc}/>
								</a>
								<a href={"https://www.facebook.com/Narodowy-Instytut-Fryderyka-Chopina-1570915943228919"} target={"_blank"} rel={"noreferrer"}>
									<img src={imageArray[5]} alt={translation.footer.right.image.fb}/>
								</a>
								<a href={"https://www.instagram.com/chopininstitute"} target={"_blank"} rel={"noreferrer"}>
									<img src={imageArray[6]} alt={translation.footer.right.image.ig}/>
								</a>
							</div>
							<div className={styles.rightBottom}>
								<span className={styles.description}>{translation.footer.right.description1}</span>
								<span className={styles.description}>{translation.footer.right.description2}</span>
								<span className={styles.description}>{translation.footer.right.description3}</span>
								<span className={styles.description}>{translation.footer.right.description4}</span>
								<a href={`tel:${translation.footer.right.description5}`} className={styles.description}>{translation.footer.right.description5}</a>
								<div>
									<a href={`mailto:${translation.footer.right.description6}`} className={styles.description}>{translation.footer.right.description6}</a>
									<span className={styles.description}>{translation.footer.right.description7}</span>
									<a href={`https://${translation.footer.right.description8}${lang === 'pl' ? '/pl' : '/en'}`} target={"_blank"} rel={"noreferrer"} className={`${styles.description} ${styles.description2}`}>{translation.footer.right.description8}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;


