import React, { useState } from "react";
import ReactPlayer from "react-player";

import PlayButton from '../../assets/images/player/playButton.png'

import styles from "./player.module.scss";

function Player(props) {

    const [play, setPlay] = useState(false)

    const playIco = () => {
        return (
            <div className={styles.background} onClick={() => setPlay(!play)}>
                <div className={styles.button} style={{backgroundImage : `url(${PlayButton})`}}/>
            </div>
        )
    }

    return (
        <div className={styles.playerWrapper}>
            <ReactPlayer className={styles.reactPlayer} url={props.data}  width={'100%'} height={'100%'} controls playIcon={playIco()} playing={play}/>
        </div>
    )
}

export default Player