import React, { useState } from 'react';
import {useHistory, useLocation, useParams} from "react-router-dom";
import queryString from "query-string";

/*import slideDownUp from "../Helpers/slideDownUp";*/
import translation from "../Helpers/translation";


import styles from './search.module.scss'


function Search() {

    const { lang } = useParams()
    const location = useLocation();
    const history = useHistory();
    const paramKeyword = queryString.parse(location.search)["keyword"]
    const paramCategory = queryString.parse(location.search)["where"]
    const [keyword, setKeyword] = useState(paramKeyword ? paramKeyword : '');
    const [category, setCategory] = useState(paramCategory ? paramCategory : "pages");



    const updateKeyWord = (e) => {
        if(e.target.value !== " ") {
            setKeyword(e.target.value)
        } else {
            e.target.value = ""
        }
    }

    const search = () => {
        if (keyword) {
            history.push(`/${lang}/wyszukiwanie/values?keyword=${encodeURI(keyword)}&where=${category}`)
        }

    }

    const searchOnKeyEnter = (e) => {
        if (e.keyCode === 13 && keyword) {
            history.push(`/${lang}/wyszukiwanie/values?keyword=${encodeURI(keyword)}&where=${category}`)
        }
    }

    /*const handleChangeSearchCategoryMenu = () => {
        const element = document.getElementsByClassName(styles.optionsMenu)[0];
        const wrapper = document.getElementsByClassName(styles.searchOptions)[0];
        const arrow = document.getElementsByClassName(styles.more)[0]
        if(wrapper.classList.contains(styles.searchOptionsOpened)) {
            wrapper.classList.remove(styles.searchOptionsOpened)
            element.children[0].style.border = "1px solid transparent"
            element.children[1].style.border = "1px solid transparent"
            arrow.classList.remove(styles.moreRotate)
        } else {
            wrapper.classList.add(styles.searchOptionsOpened)
            element.children[0].style.border = "1px solid var(--theme-color10)"
            element.children[1].style.border = "1px solid var(--theme-color10)"
            arrow.classList.add(styles.moreRotate)
        }
        slideDownUp(element,styles.optionsMenuOpen)
    }*/

    const handleChangeCategory = (event) => {
        const mainText = document.getElementsByClassName(styles.text)[0];
        mainText.innerHTML = event.target.innerHTML
        setTimeout(() => {
            setCategory(event.target.dataset.cat)
        },401)

    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.searchOptions}>
                {/*<div className={styles.more}/>*/}
                <span className={styles.text}>{category ?  translation.search[category] : translation.search.tractates}</span>
                <div className={styles.optionsMenu}>
                    <span className={`${styles.optionText} ${category === "pages" ? styles.optionTextHide : '' }`} data-cat={"pages"} onClick={handleChangeCategory}>{translation.search.pages}</span>
                    {/*<span className={`${styles.optionText} ${category === "treaties" ? styles.optionTextHide : '' }`} data-cat={"treaties"} onClick={handleChangeCategory}>{translation.search.treaties}</span>*/}
                </div>
            </div>
            <div className={styles.subWrapper}>
                <input id={"search"} type={"text"} className={styles.input} placeholder={''} value={keyword} onKeyDown={searchOnKeyEnter} onChange={updateKeyWord} aria-label={translation.search.input}/>
                <div className={styles.searchButton} onClick={search}/>
            </div>
        </div>
    )
}

export default Search;