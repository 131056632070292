import React, {useContext, useEffect, useRef, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import useWindowSize from "../../Components/Helpers/hooks/useWindowSize";
import {ThemeContext} from "../../Components/Context/context";

import Breadcrumbs from "../../Components/Breadcrumbs/breadcrumbs";

import styles from './creator.module.scss'

import translation from "../../Components/Helpers/translation";
import {Loader} from "../../Components/Loader/loader";
import axios from "axios";
import Footer from "../Footer/footer";

function Creator(props) {

    const [data, setData] = useState(null);
    const {lang, category, person} = useParams();
    const size = useWindowSize();
    const showContext = useContext(ThemeContext);
    const [imageHeight, setImageHeight] = useState(0)
    const image = useRef()
    let slug = require('slug')

    const getImageHeight = () => {
        setImageHeight(image.current.offsetHeight)
    }

    useEffect(() => {
        const image = document.getElementById("image")
        if (image) {
            image.addEventListener('load', getImageHeight);
            return () => image.removeEventListener('load', getImageHeight);
        }
    }, [data])

    useEffect(() => {
        if (imageHeight > 0) {
            image.current.style.top = (size.height - image.current.offsetHeight) / 2 + "px"
        }
    }, [imageHeight, size])

    useEffect(() => {
        const selectLink = () => {
            let link = ''

            if (category === "autorzy") {
                link = `author`
            } else if (category === "tlumacze") {
                link = `translator`
            } else if (category === "redaktorzy") {
                link = `editor`
            } else if (category === "inni") {
                link = `other`
            }
            return link
        }

        axios
            .post(`${showContext.mainUrl}${selectLink()}/search?id=${parseInt(person)}&lang=${lang}`)
            .then(res => {
                setData(res.data)
            })
    }, [category, person, lang, showContext.mainUrl])

    return (
        <>
            <Loader data={data}/>
            {data ?
                <div className={styles.wrapper}>
                    <div className={styles.subWrapper}>
                        <Breadcrumbs title={`${data.first_name} ${data.last_name}`} lang={lang}/>
                        <div className={styles.subSubWrapper}>
                            <div className={styles.imageWrapper}>
                                <img ref={image} className={styles.image} id="image"
                                     src={`${size.width > 700 ? data.image : data.mobile_image}`} alt={''}/>
                            </div>
                            <div className={styles.right}>
                                <span className={styles.name} dangerouslySetInnerHTML={{__html: `${data.first_name} ${data.last_name}`}}/>
                                <span className={styles.uni} dangerouslySetInnerHTML={{__html: data.membership}}/>
                                <span className={styles.mail} dangerouslySetInnerHTML={{__html: data.email}}/>
                                <span className={styles.translate}>{translation.creators.description[category]}</span>
                                {data.works_relation ?
                                    <div className={styles.tractateWrapper}>
                                        {data.works_relation.map((subItem,subIndex) => {
                                            return (
                                                <Link to={`/${lang}/${subItem.type_of_work === 1 ? 'artykuly-wprowadzajace' : 'traktaty' }/${subItem.works}-${slug(subItem.title)}`} key={subIndex} className={styles.tractate}>
                                                    <span dangerouslySetInnerHTML={{__html: subItem.short_desc_work}}/>
                                                </Link>
                                            )
                                        })}
                                    </div>
                                    : <span className={styles.tractate} dangerouslySetInnerHTML={{__html: data.short_desc_treaty}}/>
                                }
                                <div className={styles.text} dangerouslySetInnerHTML={{__html: data.description}}/>
                                <div className={styles.backWrapper}>
                                    <Link to={`/${lang}/tworcy/${category}`} className={styles.back}>
                                        <div className={styles.arrow}/>
                                        {translation.creators.back[category]}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                </div>
                : null
            }
        </>
    )
}

export default Creator;