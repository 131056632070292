import React, { useEffect, useState, useContext } from 'react';
import {Link, useParams} from "react-router-dom";
import {ThemeContext} from "../../Components/Context/context";
import Breadcrumbs from "../../Components/Breadcrumbs/breadcrumbs";
import {Loader} from "../../Components/Loader/loader";

import translation from "../../Components/Helpers/translation";

import styles from './creatorsPage.module.scss'
import creators_1 from '../../assets/images/creators/creators_1_black.png'
import creators_2 from '../../assets/images/creators/creators_2_black.png'
import creators_3 from '../../assets/images/creators/creators_3_black.png'
import creators_4 from '../../assets/images/creators/creators_4_black.png'
import nifc_black_pl from '../../assets/images/creators/nifc_black_pl.png'
import nifc_black_en from '../../assets/images/creators/nifc_black_en.png'
import nifc_color_pl from '../../assets/images/creators/nifc_color_pl.png'
import nifc_color_en from '../../assets/images/creators/nifc_color_en.png'
import uni_black from '../../assets/images/creators/uni_black.png'
import Footer from "../Footer/footer";

function CreatorsPage(props) {

    const [nifc, setNifc] = useState()
    const [data, setData] = useState(null);
    const showContext = useContext(ThemeContext)
    const { lang } = useParams();
    let slug = require('slug')

    useEffect(() => {
        if(showContext.theme === "light") {
            if(lang === "pl") {
                setNifc(nifc_color_pl)
            } else {
                setNifc(nifc_color_en)
            }
        } else {
            if(lang === "pl") {
                setNifc(nifc_black_pl)
            } else {
                setNifc(nifc_black_en)
            }
        }
        setTimeout(() => {
            setData([])
        },200)
    },[showContext.theme, lang])

    return (
        <>
            <Loader data={data}/>
            {data ?
                <div className={styles.wrapper}>
                    <Breadcrumbs lang={lang}/>
                    <div className={styles.listWrapper}>
                        <span className={styles.title}>{translation.creators.title.main}</span>
                        <Link to={`/${lang}/tworcy/1-${slug(translation.creators.nifc)}`} className={styles.container}>
                            <div className={styles.imageWrapper}>
                                <img src={nifc} alt={''}/>
                            </div>
                            <p>
                                <span className={styles.name}>{translation.creators.nifc}</span>
                            </p>
                        </Link>
                        <Link to={`/${lang}/tworcy/2-${slug(translation.creators.uni)}`} className={styles.container}>
                            <div className={styles.imageWrapper}>
                                <img className={styles.uni} src={uni_black} alt={''}/>
                            </div>
                            <p>
                                <span className={styles.name}>{translation.creators.uni}</span>
                            </p>
                        </Link>
                        <Link to={`/${lang}/tworcy/autorzy`} className={styles.container}>
                            <div className={styles.imageWrapper}>
                                <img className={styles.creator_1} src={creators_1} alt={''}/>
                            </div>
                            <span className={styles.name}>{translation.creators.authors}</span>
                        </Link>
                        <Link to={`/${lang}/tworcy/tlumacze`} className={styles.container}>
                            <div className={styles.imageWrapper}>
                                <img className={styles.creator_2} src={creators_2} alt={''}/>
                            </div>
                            <span className={styles.name}>{translation.creators.translators}</span>
                        </Link>
                        <Link to={`/${lang}/tworcy/redaktorzy`} className={styles.container}>
                            <div className={styles.imageWrapper}>
                                <img className={styles.creator_3} src={creators_3} alt={''}/>
                            </div>
                            <span className={styles.name}>{translation.creators.redactors}</span>
                        </Link>
                        <Link to={`/${lang}/tworcy/inni`} className={styles.container}>
                            <div className={styles.imageWrapper}>
                                <img className={styles.creator_4} src={creators_4} alt={''}/>
                            </div>
                            <span className={styles.name}>{translation.creators.others}</span>
                        </Link>
                    </div>
                    <Footer/>
                </div>
                : null
            }
        </>
    )
}

export default CreatorsPage;