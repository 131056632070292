import React, { /*lazy,*/ Suspense} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

import '../../assets/styles/index.module.scss';
import '../../assets/styles/theme.scss';

/*const Header = lazy(() => import('../Header/header'));
const Footer = lazy(() => import('../Footer/footer'));
const MainPage = lazy(() => import('../MainPage/mainPage'));
const SearchPage = lazy(() => import('../SearchPage/searchPage'));
const ArticlesPage = lazy(() => import('../ArticlesPage/articlesPage'));
const Article = lazy(() => import('../Article/article'));
const TractatePage = lazy(() => import('../TractatePage/tractatePage'));
const Tractate = lazy(() => import('../Tractate/tractate'));
const TractateAuthor = lazy(() => import('../TractateAuthor/tractateAuthor'));
const TractateShow = lazy(() => import('../TraktateShow/tractateShow'));
const AboutPage = lazy(() => import('../AboutPage/aboutPage'));
const MultimediaPage = lazy(() => import('../MultimediaPage/multimediaPage'));
const Multimedia = lazy(() => import('../Multimedia/multimedia'));
const CreatorsPage = lazy(() => import('../CreatorsPage/creatorsPage'));
const CreatorsList = lazy(() => import('../CreatorsList/creatorsList'));
const Creator = lazy(() => import('../Creator/creator'));*/

import Header from "../Header/header";
import MainPage from "../MainPage/mainPage";
import SearchPage from "../SearchPage/searchPage";
import ArticlesPage from "../ArticlesPage/articlesPage";
import Article from "../Article/article";
import TractatePage from "../TractatePage/tractatePage";
import Tractate from "../Tractate/tractate";
import TractateShow from "../TraktateShow/tractateShow";
import AboutPage from "../AboutPage/aboutPage";
import MultimediaPage from "../MultimediaPage/multimediaPage";
import Multimedia from "../Multimedia/multimedia";
import CreatorsPage from "../CreatorsPage/creatorsPage";
import CreatorsList from "../CreatorsList/creatorsList";
import Creator from "../Creator/creator";

const App = ( {match, location}) =>{

    return (
        <Suspense fallback={<div>Wczytywanie...</div>}>
            <Switch>
                <Route exact path={"/:lang(pl|en)"}>
                    <Header/>
                    <MainPage/>
                </Route>

                <Route exact path={"/:lang(pl|en)/wyszukiwanie/:params"}>
                    <Header/>
                    <SearchPage/>
                </Route>

                <Route exact path={"/:lang(pl|en)/o-projekcie/"}>
                    <Header/>
                    <AboutPage/>
                </Route>

                <Route exact path={"/:lang(pl|en)/artykuly-wprowadzajace/"}>
                    <Header/>
                    <ArticlesPage/>
                </Route>

                <Route exact path={"/:lang(pl|en)/artykuly-wprowadzajace/:id"}>
                    <Header/>
                    <Article/>
                </Route>

                <Route exact path={"/:lang(pl|en)/traktaty/"}>
                    <Header/>
                    <TractatePage/>
                </Route>

                <Route exact path={"/:lang(pl|en)/traktaty/:id"}>
                    <Header/>
                    <Tractate/>
                </Route>

                <Route exact path={"/:lang(pl|en)/traktaty/:id/widok-:params"}>
                    <Header/>
                    <TractateShow/>
                </Route>

                <Route exact path={"/:lang(pl|en)/multimedia/"}>
                    <Header/>
                    <MultimediaPage/>
                </Route>

                <Route exact path={"/:lang(pl|en)/multimedia/:id"}>
                    <Header/>
                    <Multimedia/>
                </Route>

                <Route exact path={"/:lang(pl|en)/tworcy/"}>
                    <Header/>
                    <CreatorsPage/>
                </Route>

                <Route exact path={"/:lang(pl|en)/tworcy/:category"}>
                    <Header/>
                    <CreatorsList/>
                </Route>

                <Route exact path={"/:lang(pl|en)/tworcy/:category/:person"}>
                    <Header/>
                    <Creator/>
                </Route>

                <Route exact path={`*`}>
                    <Redirect to="/pl" />
                </Route>
            </Switch>
        </Suspense>
    );
};


function Builder() {
    return (
        <Router>
            <App/>
        </Router>
    );
}

export default Builder;